import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";
import { CreateCustomersiteDTO } from "../repository/ICustomersiteRepository";

type IdentifierOrigin = { identifier: string; origin_id: number };

const fetchCustomersites = () => dependency.repositories.customersiteRepository.customersites();
const fetchCustomersite = (identifier: string) => dependency.repositories.customersiteRepository.customersite(identifier);
const createCustomersite = async (customersite: CreateCustomersiteDTO) =>
  await dependency.repositories.customersiteRepository.createCustomersite(customersite);
const updateCustomersite = async ({ identifier, customersite }: { identifier: string; customersite: CreateCustomersiteDTO }) =>
  await dependency.repositories.customersiteRepository.updateCustomersite(identifier, customersite);
const deleteCustomersite = async (identifier: string) =>
  await dependency.repositories.customersiteRepository.deleteCustomersite(identifier);
const addOriginToCustomersite = async ({ identifier, origin_id }: IdentifierOrigin) =>
  await dependency.repositories.customersiteRepository.addOriginToCustomersite(identifier, origin_id);
const deleteOriginFromCustomersite = async ({ identifier, origin_id }: IdentifierOrigin) =>
  await dependency.repositories.customersiteRepository.deleteOriginFromCustomersite(identifier, origin_id);
const updateTrigger = async (identfier: string) => await dependency.repositories.customersiteRepository.updateTrigger(identfier);

export const useCustomersites = (onError: (error: Error) => void) => useQuery("customersites", fetchCustomersites, { onError });

export const useCustomersite = (identifier: string) =>
  useQuery(["customersite", identifier], () => fetchCustomersite(identifier), {
    enabled: !!identifier,
  });

export const useCreateCustomersite = (onError: (error: Error) => void) => useMutation(createCustomersite, { onError });

export const useUpdateCustomersite = (onError: (error: Error) => void, onSuccess?: () => void) =>
  useMutation(updateCustomersite, { onError, onSuccess });

export const useDeleteCustomersite = (onSuccess: () => void, onError: (error: Error) => void) =>
  useMutation(deleteCustomersite, { onSuccess, onError });

export const useAddOriginToCustomersite = (onError: (error: Error) => void) => useMutation(addOriginToCustomersite, { onError });

export const useDeleteOriginFromCustomersite = (onError: (error: Error) => void) => useMutation(deleteOriginFromCustomersite, { onError });

export const useUpdateTrigger = (onError: (error: Error) => void) => useMutation(updateTrigger, { onError });
