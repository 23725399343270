import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { useLocation } from "react-router";
import { AppBarMenu } from "./AppBarMenu";

interface ApplicationBarProps {
  open?: boolean;
  handleDrawerOpen(): void;
  handleDrawerClose(): void;
}

export default function ApplicationBar({ open, handleDrawerOpen, handleDrawerClose }: ApplicationBarProps) {
  const location = useLocation();

  const title = React.useMemo(() => {
    const parts = location.pathname.split("/app").filter((item) => item.length > 0);
    if (parts.length === 0) return "Channel service";
    const result = parts[0].split("/")[1].replace("-", " ");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }, [location]);

  return (
    <MuiAppBar position="fixed" sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          edge="start"
          // sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <AppBarMenu />
      </Toolbar>
    </MuiAppBar>
  );
}
