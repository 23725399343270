import { Button, Grid } from "@mui/material";

interface Props {
  handleCancel(): void;
  handleSave(): void;
}

export default function Actions({ handleCancel, handleSave }: Props) {
  return (
    <Grid container justifyContent={"center"} spacing={1}>
      <Grid item>
        <Button size="small" variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button size="small" variant="contained" color="error" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
