import Api from "../../dependency/Api";
import ICustomersiteRepository, { CustomersiteDTO, CreateCustomersiteDTO } from "./ICustomersiteRepository";

export default class CustomersiteRepository implements ICustomersiteRepository {
  constructor(private readonly _api: Api) {}

  async customersites(): Promise<CustomersiteDTO[]> {
    try {
      const customersites = await this._api.request<CustomersiteDTO[]>("/customersites", "GET", undefined, true);
      return customersites;
    } catch (error) {
      throw error;
    }
  }

  async customersite(identifier: string): Promise<CustomersiteDTO> {
    try {
      const customersite = await this._api.request<CustomersiteDTO>(`/customersites/${identifier}`, "GET", undefined, true);
      return customersite;
    } catch (error) {
      throw error;
    }
  }

  async createCustomersite(customersite: CreateCustomersiteDTO): Promise<CustomersiteDTO> {
    try {
      const customersiteModel = await this._api.request<CustomersiteDTO>("/customersites", "POST", customersite, true);
      return customersiteModel;
    } catch (error) {
      throw error;
    }
  }

  async updateCustomersite(identifier: string, customersite: CreateCustomersiteDTO): Promise<CustomersiteDTO> {
    try {
      const customersiteModel = await this._api.request<CustomersiteDTO>(`/customersites/${identifier}`, "PATCH", customersite, true);
      return customersiteModel;
    } catch (error) {
      throw error;
    }
  }

  async deleteCustomersite(identifier: string): Promise<void> {
    try {
      await this._api.request(`/customersites/${identifier}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }

  async addOriginToCustomersite(identifier: string, origin_id: number): Promise<void> {
    try {
      await this._api.request(`/customersites/${identifier}/origins`, "POST", { origin_id }, true);
    } catch (error) {
      throw error;
    }
  }

  async deleteOriginFromCustomersite(identifier: string, origin_id: number): Promise<void> {
    try {
      await this._api.request(`/customersites/${identifier}/origins/${origin_id}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }

  async updateTrigger(identifier: string): Promise<void> {
    try {
      await this._api.request(`/customersites/${identifier}/updatetrigger`, "POST", undefined, true);
    } catch (error) {
      throw error;
    }
  }
}
