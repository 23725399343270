import { Container, Paper } from "@mui/material";
import PackageInfoView from "./PackageInfoView";

export default function Package() {
  return (
    <Container maxWidth="lg">
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <PackageInfoView />
      </Paper>
    </Container>
  );
}
