import Api from "../../dependency/Api";
import ITagRepository, { TagDTO } from "./ITagRepository";

export default class TagRepository implements ITagRepository {
  constructor(private readonly _api: Api) {}

  async tags(): Promise<TagDTO[]> {
    try {
      const tags = await this._api.request<TagDTO[]>("/tags", "GET", undefined, true);
      return tags;
    } catch (error) {
      throw error;
    }
  }

  async createTag(name: string): Promise<TagDTO> {
    try {
      const tag = await this._api.request<TagDTO>("/tags", "POST", { name }, true);
      return tag;
    } catch (error) {
      throw error;
    }
  }
}
