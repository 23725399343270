import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ChannelDTO } from "../../common/repository/IChannelRepository";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import styles from "../../common/styles";

interface Props {
  selectedChannels: ChannelDTO[];
  fromSelectedChannels: ChannelDTO[];
  handleClickSelectedChannel: (channel: ChannelDTO) => void;
  handleOnDragEnd: (result: DropResult) => void;
}

export default function SelectedChannelsView({
  selectedChannels,
  fromSelectedChannels,
  handleClickSelectedChannel,
  handleOnDragEnd,
}: Props) {
  return (
    <>
      <Typography variant="h5">Selected Channels</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeader}>Position</TableCell>
              <TableCell sx={styles.tableHeader}>Channel Name</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="channels">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedChannels.map((channel, index) => (
                    <Draggable key={channel.base_code} draggableId={channel.base_code} index={index}>
                      {(provided) => (
                        <TableRow
                          key={channel.base_code}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          onClick={() => handleClickSelectedChannel(channel)}
                          sx={{ backgroundColor: fromSelectedChannels.includes(channel) ? "grey" : "white" }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{channel.name}</TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
}
