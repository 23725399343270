import React from "react";
import { Grid, TextField, Tooltip } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";

type Props = {
  uploadFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
};

export default function LogoUploadView({ uploadFile, error }: Props) {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={11}>
        <TextField type="file" onChange={uploadFile} fullWidth required size="small" error={error} />
      </Grid>
      <Grid item xs={1}>
        <Tooltip
          title="Expecting 200x400px resolution and PNG format. Other resolutions might get distorted or rejected upon upload."
          arrow
          placement="top-start"
        >
          <InfoIcon color="secondary" fontSize="large" />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
