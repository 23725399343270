import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";
import { CreateStreamDTO } from "../repository/IStreamRepository";

const fetchStreams = () => dependency.repositories.streamRepository.streams();
const fetchStream = (id: number) => dependency.repositories.streamRepository.stream(id);
const createStream = async (stream: CreateStreamDTO) => await dependency.repositories.streamRepository.createStream(stream);
const updateStream = async ({ id, stream }: { id: number; stream: CreateStreamDTO }) =>
  await dependency.repositories.streamRepository.updateStream(id, stream);
const deleteStream = async (id: number) => await dependency.repositories.streamRepository.deleteStream(id);

export const useStreams = (onError: (error: Error) => void) => useQuery("streams", fetchStreams, { onError });

export const useStream = (id: number) =>
  useQuery(["stream", id], () => fetchStream(id), {
    enabled: !!id,
  });

export const useCreateStream = (onError: (error: Error) => void) => useMutation(createStream, { onError });

export const useUpdateStream = (onError: (error: Error) => void) => useMutation(updateStream, { onError });

export const useDeleteStream = (onSuccess: () => void, onError: (error: Error) => void) =>
  useMutation(deleteStream, { onSuccess, onError });
