import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";
import { CreateChannelDTO, UpdateChannelDTO } from "../repository/IChannelRepository";

type ChannelTag = {
  base_code: string;
  tag_id: number;
};

const fetchChannels = () => dependency.repositories.channelRepository.channels();
const fetchChannel = (base_code: string) => dependency.repositories.channelRepository.channel(base_code);
const createChannel = async (channel: CreateChannelDTO) => await dependency.repositories.channelRepository.createChannel(channel);
const updateChannel = async ({ base_code, channel }: { base_code: string; channel: UpdateChannelDTO }) =>
  await dependency.repositories.channelRepository.updateChannel(base_code, channel);
const deleteChannel = async (base_code: string) => await dependency.repositories.channelRepository.deleteChannel(base_code);
const addTagToChannel = async ({ base_code, tag_id }: ChannelTag) =>
  await dependency.repositories.channelRepository.addTagToChannel(base_code, tag_id);
const deleteTagFromChannel = async ({ base_code, tag_id }: ChannelTag) =>
  await dependency.repositories.channelRepository.deleteTagFromChannel(base_code, tag_id);

export const useChannels = (onError: (error: Error) => void) => useQuery("channels", fetchChannels, { onError });

export const useChannel = (base_code: string) =>
  useQuery(["channel", base_code], () => fetchChannel(base_code), {
    enabled: !!base_code,
  });

export const useCreateChannel = (onError: (error: Error) => void) => useMutation(createChannel, { onError });

export const useUpdateChannel = (onError: (error: Error) => void) => useMutation(updateChannel, { onError });

export const useDeleteChannel = (onSuccess: () => void, onError: (error: Error) => void) =>
  useMutation(deleteChannel, { onSuccess, onError });

export const useAddTagToChannel = (onError: (error: Error) => void) => useMutation(addTagToChannel, { onError });

export const useDeleteTagFromChannel = (onError: (error: Error) => void) => useMutation(deleteTagFromChannel, { onError });
