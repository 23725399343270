import IChannelRepository from "../common/repository/IChannelRepository";
import ChannelRepository from "../common/repository/ChannelRepository";
import IOriginRepository from "../common/repository/IOriginRepository";
import OriginRepository from "../common/repository/OriginRepository";
import Api from "./Api";
import IHttpRequester from "./httpRequester/IHttpRequester";
import TokenManager from "./TokenManager";
import ITagRepository from "../common/repository/ITagRepository";
import TagRepository from "../common/repository/TagRepository";
import IPackageRepository from "../common/repository/IPackageRepository";
import PackageRepository from "../common/repository/PackageRepository";
import ILineupRepository from "../common/repository/ILineupRepository";
import LineupRepository from "../common/repository/LineupRepository";
import IStreamRepository from "../common/repository/IStreamRepository";
import StreamRepository from "../common/repository/StreamRepository";
import ICustomersiteRepository from "../common/repository/ICustomersiteRepository";
import CustomersiteRepository from "../common/repository/CustomersiteRepository";
import ILogoRepository from "../common/repository/ILogoRepository";
import LogoRepository from "../common/repository/LogoRepository";

export default class Repositories {
  public readonly channelRepository: IChannelRepository;
  public readonly tagRepository: ITagRepository;
  public readonly originRepository: IOriginRepository;
  public readonly packageRepository: IPackageRepository;
  public readonly lineupRepository: ILineupRepository;
  public readonly streamRepository: IStreamRepository;
  public readonly customersiteRepository: ICustomersiteRepository;
  public readonly logoRepository: ILogoRepository;

  constructor(env: any, requester: IHttpRequester, tokenManager: TokenManager) {
    const api = new Api(env.REACT_APP_BACKEND_URL, requester);
    this.channelRepository = new ChannelRepository(api);
    this.tagRepository = new TagRepository(api);
    this.originRepository = new OriginRepository(api);
    this.packageRepository = new PackageRepository(api);
    this.lineupRepository = new LineupRepository(api);
    this.streamRepository = new StreamRepository(api);
    this.customersiteRepository = new CustomersiteRepository(api);
    this.logoRepository = new LogoRepository(api);
  }
}
