import { useState } from "react";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { CustomErrorOverlay } from "../../common/components/table/CustomErrorOverlay";
import { CustomLoadingOverlay } from "../../common/components/table/CustomLoadingOverlay";
import { CustomNoResultOverlay } from "../../common/components/table/CustomNoResultOverlay";
import { CustomNoRowsOverlay } from "../../common/components/table/CustomNoRowsOverlay";
import columns, { getOriginsColumn, getActionsColumn } from "./Columns";
import { CustomToolbar } from "./CustomToolbar";
import { CustomersiteDTO } from "../../common/repository/ICustomersiteRepository";

interface Props {
  customersites: CustomersiteDTO[];
  openDialog(id: string, data: any): void;
}

export default function BalancingListView({ customersites, openDialog }: Props) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onPageChange = (page: number) => setPage(page);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const onSelectionModelChange = (newSelectionModel: GridSelectionModel) => setSelectionModel(newSelectionModel);

  const originsColumn = getOriginsColumn({ openDialog });
  const actionsColumn = getActionsColumn({ openDialog });

  return (
    <DataGrid
      autoHeight
      rows={customersites}
      columns={[...columns, originsColumn, actionsColumn]}
      getRowId={(row) => row.identifier}
      pageSize={pageSize}
      page={page}
      density="standard"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableSelectionOnClick
      checkboxSelection
      disableColumnMenu
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
        NoRowsOverlay: CustomNoRowsOverlay,
        ErrorOverlay: CustomErrorOverlay,
        Toolbar: CustomToolbar,
        NoResultsOverlay: CustomNoResultOverlay,
      }}
      componentsProps={{
        toolbar: { openDialog, identifiers: selectionModel },
      }}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selectionModel}
    />
  );
}
