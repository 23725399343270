import AuthService from "../auth/AuthService";
import HttpRequester from "./httpRequester/HttpRequester";
import TokenManager from "./TokenManager";
import Repositories from "./Repositories";

interface Dependency {
  repositories: Repositories;
  authService: AuthService;
  tokenManager: TokenManager;
  create(env: any): void;
}

export default class AppDependency implements Dependency {
  public repositories!: Repositories;
  public authService!: AuthService;
  public tokenManager!: TokenManager;
  public backendUrl!: string;

  create(env: any) {
    this.tokenManager = new TokenManager();
    const { REACT_APP_BACKEND_URL } = env;
    this.backendUrl = REACT_APP_BACKEND_URL;
    const requester = new HttpRequester(REACT_APP_BACKEND_URL, this.tokenManager);
    this.authService = new AuthService(REACT_APP_BACKEND_URL, requester, this.tokenManager);
    this.repositories = new Repositories(env, requester, this.tokenManager);
  }
}
