import { Button, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { DELETE_CUSTOMERSITE_DIALOG_ID } from "./DeleteCustomersiteDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Customer Site",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Tooltip title="Edit Customer Site" placement="top-start" arrow>
        <Link component={RouterLink} to={`/app/customersites/${params.row.identifier}`}>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "identifier",
    headerName: "Customer Identifier",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "lineup_id",
    headerName: "Lineup",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

interface Props {
  openDialog(id: string, data: any): void;
}

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <Button onClick={() => openDialog(DELETE_CUSTOMERSITE_DIALOG_ID, { identifier: params.row.identifier, name: params.row.name })}>
      <DeleteIcon />
    </Button>
  ),
});

export default columns;
