import useDialog from "../../common/hook/useDialog";
import PageError from "../../common/components/PageError";
import PageLoading from "../../common/components/PageLoading";
import { useCallback, useState } from "react";
import LineupListView from "./LineupListView";
import { useLineups } from "../../common/hook/useLineupData";
import DeleteLineupDialog from "./DeleteLineupDialog";

export default function LineupList() {
  const [errorMessage, setErrorMessage] = useState("");

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { data: lineups, isLoading } = useLineups(onError);

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (errorMessage) return <PageError message={errorMessage} />;

  if (isLoading) return <PageLoading />;

  return (
    <>
      <LineupListView lineups={lineups || []} openDialog={openDialog} />
      <DeleteLineupDialog data={dialogData} close={closeDialog} />
    </>
  );
}
