import { Box, DialogContent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { OriginDTO } from "../../common/repository/IOriginRepository";
import { StreamDTO } from "../../common/repository/IStreamRepository";

type Props = {
  data: DialogData;
  close(): void;
  origins: OriginDTO[];
};

export const STREAMS_CHANNEL_DIALOG_ID = "streams-channel";

export default function StreamsChannelDialog({ data, close, origins }: Props) {
  const { streams } = data.data;

  if (data.id !== STREAMS_CHANNEL_DIALOG_ID) return null;

  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted fullWidth maxWidth="xs">
      <DialogContent>
        {streams.map((stream: StreamDTO) => (
          <Box key={stream.id}>
            {stream.code} ({origins.find((origin) => origin.id === stream.origin_id)?.name})
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained" size="small">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
