import { DialogContent, DialogContentText } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { UpTransition } from "../../common/components/UpTransition";

type Props = {
  updateCustomersites: (active: boolean) => void;
  close(): void;
  errorMessage: string;
};

export default function BulkActionDeactivationView({ updateCustomersites, close, errorMessage }: Props) {
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setInputValue(e.target.value),
    [setInputValue]
  );

  const setInitialState = useCallback(() => {
    setInputError(false);
    setInputValue("");
  }, [setInputError, setInputValue]);

  const handleCancel = useCallback(() => {
    setInitialState();
    close();
  }, [close, setInitialState]);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (inputValue === "yesiunderstand") {
        await updateCustomersites(false);
        setInputError(false);
        handleCancel();
      } else {
        setInputError(true);
      }
    },
    [inputValue, updateCustomersites, setInputError, handleCancel]
  );

  return (
    <Dialog
      open={true}
      TransitionComponent={UpTransition}
      keepMounted
      aria-describedby="bulk-action-deactivation-dialog"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>This will disable all TV Streaming for all selected customer sites until reactivated</DialogContentText>
          <EmptyRow />
          <CustomTextField label='Enter the "yesiunderstand" for continue' value={inputValue} onChange={onChange} error={inputError} />
          <EmptyRow />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small" color="error">
            Deactivate
          </Button>
        </DialogActions>
      </form>
      <CommandError message={errorMessage} />
    </Dialog>
  );
}
