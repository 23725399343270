export type OriginDTO = {
  id: number;
  name: string;
  url: string;
  active: boolean;
  scheme: Scheme;
};

export type OriginModel = {
  name?: string;
  url?: string;
  active?: boolean;
  scheme?: Scheme;
};

export enum Scheme {
  http = "http",
  https = "https",
}

export default interface IOriginRepository {
  origins(): Promise<OriginDTO[]>;
  origin(id: number): Promise<OriginDTO>;
  createOrigin(origin: OriginModel): Promise<OriginDTO>;
  updateOrigin(id: number, origin: OriginModel): Promise<OriginDTO>;
  deleteOrigin(id: number): Promise<void>;
}
