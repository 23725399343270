import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import PageError from "../../common/components/PageError";
import PageLoading from "../../common/components/PageLoading";
import { useChannels } from "../../common/hook/useChannelData";
import {
  useAddChannelToPackage,
  useCreatePackage,
  useDeleteChannelFromPackage,
  usePackage,
  useUpdateChannelPosition,
  useUpdatePackage,
} from "../../common/hook/usePackageData";
import { ChannelDTO } from "../../common/repository/IChannelRepository";
import { DropResult } from "react-beautiful-dnd";
import SelectedChannelsView from "./SelectedChannelsView";
import ArrowsView from "../../common/components/ArrowsView";
import AvailableChannelsView from "./AvailableChannelsView";
import { useQueryClient } from "react-query";
import { EmptyRow } from "../../common/components/EmptyRow";

export default function PackageInfoView() {
  const [name, setName] = useState("");
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [availableChannels, setAvailableChannels] = useState<ChannelDTO[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<ChannelDTO[]>([]);
  const [fromAvailableChannels, setFromAvailableChannels] = useState<ChannelDTO[]>([]);
  const [fromSelectedChannels, setFromSelectedChannels] = useState<ChannelDTO[]>([]);

  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params.id);

  const queryClient = useQueryClient();

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const onSuccess = useCallback(() => queryClient.invalidateQueries("packages"), [queryClient]);

  const { data: pack, isLoading, error } = usePackage(id);

  const { data: channels } = useChannels(onError);

  useEffect(() => {
    if (pack) {
      setName(pack.name);
      setAvailableChannels(channels?.filter((channel) => !pack.channels.find((packCh) => packCh.base_code === channel.base_code)) || []);
      setSelectedChannels(pack.channels);
    } else {
      setAvailableChannels(channels || []);
    }
  }, [pack, setName, channels, setAvailableChannels]);

  const { mutateAsync: createPackage } = useCreatePackage(onError);
  const { mutateAsync: updatePackage } = useUpdatePackage(onError);
  const { mutateAsync: addChannelToPackage } = useAddChannelToPackage(onError, onSuccess);
  const { mutateAsync: deleteChannelFromPackage } = useDeleteChannelFromPackage(onError);
  const { mutateAsync: updateChannelPosition } = useUpdateChannelPosition(onError);

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(e.target.value);
    },
    [setName]
  );

  const handleCancel = useCallback(() => navigate("/app/packages"), [navigate]);

  const handleSave = useCallback(async () => {
    if (name.length > 0) {
      setIsSaveClicked(true);
      if (id) {
        const pack = await updatePackage({ id, name });
        const oldChannels = pack.channels.filter((packCh) => !selectedChannels.find((channel) => channel.base_code === packCh.base_code));
        await Promise.all(oldChannels.map((channel) => deleteChannelFromPackage({ id: pack.id, base_code: channel.base_code })));

        let channelIndex = 1;
        for (const channel of selectedChannels) {
          if (pack.channels.find((packCh, packChIndex) => packCh.base_code === channel.base_code && channelIndex !== packChIndex + 1)) {
            await updateChannelPosition({ id: pack.id, base_code: channel.base_code, position: channelIndex });
          }
          if (!pack.channels.find((packCh) => packCh.base_code === channel.base_code)) {
            await addChannelToPackage({ id: pack.id, base_code: channel.base_code });
          }
          channelIndex++;
        }
      } else {
        const pack = await createPackage(name);
        for (const channel of selectedChannels) {
          await addChannelToPackage({ id: pack.id, base_code: channel.base_code });
        }
      }
      handleCancel();
    } else {
      setFormError(true);
    }
  }, [
    name,
    setFormError,
    id,
    updatePackage,
    createPackage,
    selectedChannels,
    addChannelToPackage,
    deleteChannelFromPackage,
    updateChannelPosition,
    handleCancel,
    setIsSaveClicked,
  ]);

  const handleClickAvailableChannel = useCallback(
    (channel: ChannelDTO) =>
      setFromAvailableChannels(
        fromAvailableChannels.includes(channel)
          ? fromAvailableChannels.filter((fromAvailableChannel) => fromAvailableChannel.base_code !== channel.base_code)
          : [...fromAvailableChannels, channel]
      ),
    [fromAvailableChannels, setFromAvailableChannels]
  );

  const handleClickSelectedChannel = useCallback(
    (channel: ChannelDTO) =>
      setFromSelectedChannels(
        fromSelectedChannels.includes(channel)
          ? fromSelectedChannels.filter((fromSelectedChannel) => fromSelectedChannel.base_code !== channel.base_code)
          : [...fromSelectedChannels, channel]
      ),
    [fromSelectedChannels, setFromSelectedChannels]
  );

  const fromAvailableToSelected = useCallback(() => {
    setSelectedChannels(selectedChannels.concat(fromAvailableChannels));
    setAvailableChannels(availableChannels.filter((availableChannel) => !fromAvailableChannels.includes(availableChannel)));
    setFromAvailableChannels([]);
  }, [fromAvailableChannels, setFromAvailableChannels, selectedChannels, setSelectedChannels, availableChannels, setAvailableChannels]);

  const fromSelectedToAvailable = useCallback(() => {
    setAvailableChannels(availableChannels.concat(fromSelectedChannels));
    setSelectedChannels(selectedChannels.filter((selectedChannel) => !fromSelectedChannels.includes(selectedChannel)));
    setFromSelectedChannels([]);
  }, [fromSelectedChannels, setFromSelectedChannels, selectedChannels, setSelectedChannels, availableChannels, setAvailableChannels]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const tmp = selectedChannels[result.source.index];
      selectedChannels[result.source.index] = selectedChannels[result.destination.index];
      selectedChannels[result.destination.index] = tmp;
      setSelectedChannels(selectedChannels);
    },
    [selectedChannels, setSelectedChannels]
  );

  if (error instanceof Error) return <PageError message={error.message} />;

  if (isLoading || (isSaveClicked && !errorMessage)) return <PageLoading />;

  return (
    <>
      <Container maxWidth="sm">
        <CustomTextField label="Package Name" value={name} onChange={onNameChange} required error={formError} />
      </Container>

      <Grid container sx={{ mt: "5%", mb: "5%" }}>
        <Grid item xs={5}>
          <SelectedChannelsView
            selectedChannels={selectedChannels}
            fromSelectedChannels={fromSelectedChannels}
            handleClickSelectedChannel={handleClickSelectedChannel}
            handleOnDragEnd={handleOnDragEnd}
          />
        </Grid>
        <Grid item xs={2}>
          <EmptyRow />
          <EmptyRow />
          <ArrowsView fromAvailableToSelected={fromAvailableToSelected} fromSelectedToAvailable={fromSelectedToAvailable} />
        </Grid>
        <Grid item xs={5}>
          <AvailableChannelsView
            availableChannels={availableChannels}
            fromAvailableChannels={fromAvailableChannels}
            handleClickAvailableChannel={handleClickAvailableChannel}
          />
        </Grid>
      </Grid>

      <Actions handleCancel={handleCancel} handleSave={handleSave} />

      <CommandError message={errorMessage} />
    </>
  );
}
