import React from "react";
import { Grid, Paper } from "@mui/material";
import PackageList from "./PackageList";

export default function Packages() {
  return (
    <Grid item xs={12} lg={8}>
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <PackageList />
      </Paper>
    </Grid>
  );
}
