import { Button, Chip, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ORIGINS_CUSTOMERSITE_DIALOG_ID } from "./OriginsDialog";
import { ACTIVE_CUSTOMERSITE_DIALOG_ID } from "./ActiveCustomersiteDialog";
import { UPDATE_TRIGGER_DIALOG_ID } from "./UpdateTriggerDialog";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SyncIcon from "@mui/icons-material/Sync";
import { OriginDTO } from "../../common/repository/IOriginRepository";

const columns: GridColDef[] = [
  {
    field: "identifier",
    headerName: "Identifier",
    flex: 1,
    hide: true,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Customer Site",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "active",
    headerName: "Status",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) =>
      params.row.update_pending ? (
        <Chip variant="filled" color="error" sx={{ m: 0.2 }} label={"Pending Update"} />
      ) : params.row.active ? (
        <Chip variant="filled" color="primary" sx={{ m: 0.2 }} label={"Active"} />
      ) : (
        <Chip variant="filled" color="secondary" sx={{ m: 0.2 }} label={"Inactive"} />
      ),
  },
];

interface Props {
  openDialog(id: string, data: any): void;
}

export const getOriginsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "origins",
  headerName: "Available Origins",
  flex: 1,
  align: "center",
  headerAlign: "center",
  renderCell: (params) => (
    <Tooltip title="Change Origins" placement="top-start" arrow>
      <Button
        onClick={() =>
          openDialog(ORIGINS_CUSTOMERSITE_DIALOG_ID, {
            value: params.value.map((origin: OriginDTO) => origin.id),
            identifier: params.row.identifier,
          })
        }
      >
        {params.value.length > 0
          ? params.value.map((item: OriginDTO, index: number) => {
              return <span key={index}>{(index ? ", " : "") + item.name}</span>;
            })
          : "no origin selected"}
      </Button>
    </Tooltip>
  ),
});

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <Button
        onClick={() =>
          openDialog(ACTIVE_CUSTOMERSITE_DIALOG_ID, { identifier: params.row.identifier, name: params.row.name, active: params.row.active })
        }
      >
        {params.row.active === true ? (
          <Tooltip title="Deactivate" placement="top-start" arrow>
            <NotInterestedIcon />
          </Tooltip>
        ) : (
          <Tooltip title="Activate" placement="top-start" arrow>
            <TaskAltIcon />
          </Tooltip>
        )}
      </Button>
      <Button onClick={() => openDialog(UPDATE_TRIGGER_DIALOG_ID, { identifier: params.row.identifier })}>
        <Tooltip title="Update Lineup" placement="top-start" arrow>
          <SyncIcon />
        </Tooltip>
      </Button>
    </>
  ),
});

export default columns;
