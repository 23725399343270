import { Button, Chip, Grid, TextField } from "@mui/material";
import { EmptyRow } from "../../common/components/EmptyRow";

interface Props {
  tag: string;
  tags: string[];
  handleChangeTag: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddTag: () => void;
  handleDeleteTag: (tagToDelete: string) => void;
}

export default function TagsView({ tag, tags, handleChangeTag, handleAddTag, handleDeleteTag }: Props) {
  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item xs={10}>
          <TextField label="Tags" variant="outlined" size="small" fullWidth value={tag} onChange={handleChangeTag} />
        </Grid>
        <Grid item xs={2}>
          <Button size="small" variant="contained" onClick={handleAddTag}>
            Add
          </Button>
        </Grid>
      </Grid>

      <EmptyRow />

      {tags.map((tag) => (
        <Chip label={tag} variant="filled" color="secondary" sx={{ m: 0.2 }} key={tag} onDelete={() => handleDeleteTag(tag)} />
      ))}
    </>
  );
}
