import Api from "../../dependency/Api";
import IPackageRepository, { PackageDTO } from "./IPackageRepository";

export default class PackageRepository implements IPackageRepository {
  constructor(private readonly _api: Api) {}

  async packages(): Promise<PackageDTO[]> {
    try {
      const packages = await this._api.request<PackageDTO[]>("/packages", "GET", undefined, true);
      return packages;
    } catch (error) {
      throw error;
    }
  }

  async package(id: number): Promise<PackageDTO> {
    try {
      const pack = await this._api.request<PackageDTO>(`/packages/${id}`, "GET", undefined, true);
      return pack;
    } catch (error) {
      throw error;
    }
  }

  async createPackage(name: string): Promise<PackageDTO> {
    try {
      const newPackage = await this._api.request<PackageDTO>("/packages", "POST", { name }, true);
      return newPackage;
    } catch (error) {
      throw error;
    }
  }

  async updatePackage(id: number, name: string): Promise<PackageDTO> {
    try {
      const newPackage = await this._api.request<PackageDTO>(`/packages/${id}`, "PATCH", { name }, true);
      return newPackage;
    } catch (error) {
      throw error;
    }
  }

  async deletePackage(id: number): Promise<void> {
    try {
      await this._api.request(`/packages/${id}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }

  async addChannelToPackage(id: number, base_code: string): Promise<PackageDTO> {
    try {
      const pack = await this._api.request<PackageDTO>(`/packages/${id}/channels`, "POST", { base_code }, true);
      return pack;
    } catch (error) {
      throw error;
    }
  }

  async deleteChannelFromPackage(id: number, base_code: string): Promise<PackageDTO> {
    try {
      const pack = await this._api.request<PackageDTO>(`/packages/${id}/channels/${base_code}`, "DELETE", undefined, true);
      return pack;
    } catch (error) {
      throw error;
    }
  }

  async updateChannelPosition(id: number, base_code: string, position: number): Promise<PackageDTO> {
    try {
      const pack = await this._api.request<PackageDTO>(`/packages/${id}/channels/${base_code}`, "PATCH", { position }, true);
      return pack;
    } catch (error) {
      throw error;
    }
  }
}
