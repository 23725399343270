import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import CommandError from "../../common/components/CommandError";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { useDeleteCustomersite } from "../../common/hook/useCustomersiteData";
import { DialogContent } from "@mui/material";
import { EmptyRow } from "../../common/components/EmptyRow";
import CustomTextField from "../../common/components/basics/CustomTextField";

type Props = {
  data: DialogData;
  close(): void;
};

export const DELETE_CUSTOMERSITE_DIALOG_ID = "delete-customersite";

export default function DeleteCustomersiteDialog({ data, close }: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteCustomersiteName, setDeleteCustomersiteName] = useState("");
  const [formError, setFormError] = useState(false);

  const { identifier, name } = data.data;

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.setQueryData("customersites", (oldQueryData: any) => {
      return oldQueryData.filter((oldData: any) => oldData.identifier !== identifier);
    });
  }, [identifier, queryClient]);

  const onError = useCallback((error: Error) => setErrorMessage(error.message), [setErrorMessage]);

  const { mutateAsync: deleteCustomersite } = useDeleteCustomersite(onSuccess, onError);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;

    setDeleteCustomersiteName(value);
  };

  const handleClose = useCallback(() => {
    setFormError(false);
    setErrorMessage("");
    setDeleteCustomersiteName("");
    close();
  }, [close, setFormError, setErrorMessage, setDeleteCustomersiteName]);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (deleteCustomersiteName === name) {
        await deleteCustomersite(identifier);
        handleClose();
      } else {
        setFormError(true);
      }
    },
    [handleClose, deleteCustomersite, deleteCustomersiteName, identifier, name]
  );

  if (data.id !== DELETE_CUSTOMERSITE_DIALOG_ID) return null;

  return (
    <Dialog
      open={true}
      TransitionComponent={UpTransition}
      keepMounted
      aria-describedby="delete-customersite-dialog"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This will make all TV Streaming unavailable for the customer site until reconfigured
          <EmptyRow />
          <CustomTextField
            label="Enter the customer site name to confirm deletion"
            onChange={onChange}
            error={formError}
            value={deleteCustomersiteName}
            hasHelperText
          />
          <EmptyRow />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" color="error" variant="contained" size="small">
            Delete
          </Button>
        </DialogActions>
        <CommandError message={errorMessage} />
      </form>
    </Dialog>
  );
}
