import React from "react";
import "./App.css";
import Channels from "./features/channels/Channels";
import Origins from "./features/origins/Origins";
import { createBrowserRouter, Outlet, RouterProvider, useNavigate } from "react-router-dom";
import SignIn from "./features/signIn/SignIn";
import { useTokenStatus } from "./useTokenStatus";
import TokenManager from "./dependency/TokenManager";
import Channel from "./features/channel/Channel";
import NotFoundPage from "./appShell/NotFoundPage";
import AppShell from "./appShell/AppShell";
import Origin from "./features/origin/Origin";
import Packages from "./features/packages/Packages";
import Package from "./features/package/Package";
import Logos from "./features/logos/Logos";
import Logo from "./features/logo/Logo";
import Lineups from "./features/lineups/Lineups";
import Lineup from "./features/lineup/Lineup";
import Streams from "./features/streams/Streams";
import Stream from "./features/stream/Stream";
import Customersites from "./features/customersites/Customersites";
import Customersite from "./features/customersite/Customersite";
import Balancing from "./features/balancing/Balancing";

function Root() {
  const [originalUrl, setOriginalUrl] = React.useState<string | null>(window.location.pathname);
  const hasToken = useTokenStatus(TokenManager.KEY);
  const navigate = useNavigate();

  React.useEffect(() => {
    const noToken = () => navigate("/sign-in");
    const token = () => {
      const current = window.location.pathname;
      if (current.startsWith("/app")) {
        if (originalUrl !== null) setOriginalUrl(null);
        return;
      }
      let nextUrl = "/app";
      if (originalUrl !== null) {
        nextUrl = originalUrl.startsWith("/app") ? originalUrl : "/app";
        setOriginalUrl(null);
      }
      if (current === nextUrl) return;
      navigate(nextUrl);
    };
    hasToken ? token() : noToken();
  }, [hasToken, navigate, originalUrl, setOriginalUrl]);

  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "app",
        element: <AppShell />,
        children: [
          {
            path: "channels",
            element: <Channels />,
          },
          {
            path: "channels/add",
            element: <Channel />,
          },
          {
            path: "channels/:base_code",
            element: <Channel />,
          },
          {
            path: "origins",
            element: <Origins />,
          },
          {
            path: "origins/add",
            element: <Origin />,
          },
          {
            path: "origins/:id",
            element: <Origin />,
          },
          {
            path: "packages",
            element: <Packages />,
          },
          {
            path: "packages/add",
            element: <Package />,
          },
          {
            path: "packages/:id",
            element: <Package />,
          },
          {
            path: "lineups",
            element: <Lineups />,
          },
          {
            path: "lineups/add",
            element: <Lineup />,
          },
          {
            path: "lineups/:id",
            element: <Lineup />,
          },
          {
            path: "logos",
            element: <Logos />,
          },
          {
            path: "logos/add",
            element: <Logo />,
          },
          {
            path: "logos/:name",
            element: <Logo />,
          },
          {
            path: "streams",
            element: <Streams />,
          },
          {
            path: "streams/add",
            element: <Stream />,
          },
          {
            path: "streams/:id",
            element: <Stream />,
          },
          {
            path: "customersites",
            element: <Customersites />,
          },
          {
            path: "customersites/add",
            element: <Customersite />,
          },
          {
            path: "customersites/:identifier",
            element: <Customersite />,
          },
          {
            path: "balancing",
            element: <Balancing />,
          },
        ],
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
