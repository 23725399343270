import { Grid, Tooltip } from "@mui/material";
import CustomTextField from "../../common/components/basics/CustomTextField";
import { Info as InfoIcon } from "@mui/icons-material";

interface Props {
  epg_channel_id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function EpgView({ epg_channel_id, onChange }: Props) {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={11}>
        <CustomTextField label="EPG ID" value={epg_channel_id} onChange={onChange} />
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="The EPG ID is used to retrive EPG data from a related service and defined per channel" arrow placement="top-start">
          <InfoIcon color="secondary" fontSize="large" />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
