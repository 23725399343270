import { Alert, AlertTitle, Box } from "@mui/material";

export default function PageError({ message }: { message: string }) {
  // if (!message) return null;
  return (
    <Box sx={{ p: 2 }}>
      <Alert severity="error">
        <AlertTitle>Page loading failed</AlertTitle>
        {message}
      </Alert>
    </Box>
  );
}
