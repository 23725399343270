import { Grid, Paper } from "@mui/material";
import CustomersiteList from "./CustomersiteList";

export default function Customersites() {
  return (
    <Grid item xs={12} lg={8}>
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <CustomersiteList />
      </Paper>
    </Grid>
  );
}
