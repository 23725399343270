import Api from "../../dependency/Api";
import IChannelRepository, { ChannelDTO, CreateChannelDTO, UpdateChannelDTO } from "./IChannelRepository";

export default class ChannelRepository implements IChannelRepository {
  constructor(private readonly _api: Api) {}

  async channels(): Promise<ChannelDTO[]> {
    try {
      const channels = await this._api.request<ChannelDTO[]>("/channels", "GET", undefined, true);
      return channels;
    } catch (error) {
      throw error;
    }
  }

  async channel(base_code: string): Promise<ChannelDTO> {
    try {
      const channel = await this._api.request<ChannelDTO>(`/channels/${base_code}`, "GET", undefined, true);
      return channel;
    } catch (error) {
      throw error;
    }
  }

  async createChannel(channel: CreateChannelDTO): Promise<ChannelDTO> {
    try {
      const channelModel = await this._api.request<ChannelDTO>("/channels", "POST", channel, true);
      return channelModel;
    } catch (error) {
      throw error;
    }
  }

  async updateChannel(base_code: string, channel: UpdateChannelDTO): Promise<ChannelDTO> {
    try {
      const channelModel = await this._api.request<ChannelDTO>(`/channels/${base_code}`, "PATCH", channel, true);
      return channelModel;
    } catch (error) {
      throw error;
    }
  }

  async deleteChannel(base_code: string): Promise<void> {
    try {
      await this._api.request(`/channels/${base_code}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }

  async addTagToChannel(base_code: string, tag_id: number): Promise<ChannelDTO> {
    try {
      const channel = await this._api.request<ChannelDTO>(`/channels/${base_code}/tags`, "POST", { tag_id }, true);
      return channel;
    } catch (error) {
      throw error;
    }
  }

  async deleteTagFromChannel(base_code: string, tag_id: number): Promise<ChannelDTO> {
    try {
      const channel = await this._api.request<ChannelDTO>(`/channels/${base_code}/tags/${tag_id}`, "DELETE", undefined, true);
      return channel;
    } catch (error) {
      throw error;
    }
  }
}
