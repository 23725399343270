import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { useDeleteLineup } from "../../common/hook/useLineupData";
import DeleteErrorView from "./DeleteErrorView";
import LineupDeletedView from "./LineupDeletedView";

type Props = {
  data: DialogData;
  close(): void;
};

export const DELETE_LINEUP_DIALOG_ID = "delete-lineup";

export default function DeleteLineupDialog({ data, close }: Props) {
  const { id } = data.data;
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.setQueryData("lineups", (oldQueryData: any) => {
      return oldQueryData.filter((oldData: any) => oldData.id !== id);
    });
  }, [id, queryClient]);

  const onError = useCallback((error: Error) => setErrorMessage(error.message), [setErrorMessage]);

  const { mutateAsync: deleteLineup } = useDeleteLineup(onSuccess, onError);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await deleteLineup(id);
      setIsSuccess(true);
    },
    [id, deleteLineup, setIsSuccess]
  );

  const handleCancel = useCallback(() => {
    close();
    setIsSuccess(false);
    setErrorMessage("");
  }, [close, setIsSuccess, setErrorMessage]);

  if (data.id !== DELETE_LINEUP_DIALOG_ID) return null;

  if (isSuccess) return <LineupDeletedView close={handleCancel} />;
  if (errorMessage) return <DeleteErrorView close={handleCancel} errorMessage={errorMessage} />;

  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="delete-lineup-dialog" fullWidth maxWidth="xs">
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>Deletion is only possible when no customer sites are using the lineup.</DialogContent>
        <DialogActions>
          <Button onClick={close} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" color="error" variant="contained" size="small">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
