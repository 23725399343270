import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { PackageDTO } from "../../common/repository/IPackageRepository";
import styles from "../../common/styles";

interface Props {
  selectedPackages: PackageDTO[];
  fromSelectedPackages: PackageDTO[];
  handleClickSelectedPackage: (pack: PackageDTO) => void;
  handleOnDragEnd: (result: DropResult) => void;
}

export default function SelectedPackagesView({
  selectedPackages,
  fromSelectedPackages,
  handleClickSelectedPackage,
  handleOnDragEnd,
}: Props) {
  return (
    <>
      <Typography variant="h5">Selected Packages</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeader}>Position</TableCell>
              <TableCell sx={styles.tableHeader}>Package Name</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="packages">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedPackages.map((pack, index) => (
                    <Draggable key={pack.id} draggableId={pack.id.toString()} index={index}>
                      {(provided) => (
                        <TableRow
                          key={pack.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          onClick={() => handleClickSelectedPackage(pack)}
                          sx={{ backgroundColor: fromSelectedPackages.includes(pack) ? "grey" : "white" }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{pack.name}</TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
}
