import { FormControl, FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import PageError from "../../common/components/PageError";
import PageLoading from "../../common/components/PageLoading";
import { useCreateCustomersite, useCustomersite, useUpdateCustomersite } from "../../common/hook/useCustomersiteData";
import { useLineups } from "../../common/hook/useLineupData";
import { CreateCustomersiteDTO } from "../../common/repository/ICustomersiteRepository";
import { Info as InfoIcon } from "@mui/icons-material";
import CustomAutocomplete, { OptionType } from "../../common/components/basics/CustomAutocomplete";

type QuestionnaireType = "identifier" | "name";

const initialCustomersite: CreateCustomersiteDTO = {
  identifier: "",
  name: "",
  lineup_id: 0,
  active: false,
};

const initialFormError = {
  identifier: false,
  name: false,
  lineup: false,
};

export default function CustomersiteInfoView() {
  const [customersite, setCustomersite] = useState(initialCustomersite);
  const [formError, setFormError] = useState(initialFormError);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const identifier = params.identifier as string;

  const { data, isLoading, error } = useCustomersite(identifier);

  useEffect(() => {
    if (data) {
      setCustomersite({
        identifier: data.identifier,
        name: data.name,
        lineup_id: data.lineup_id,
        active: data.active,
      });
    } else {
      setCustomersite(initialCustomersite);
    }
  }, [data, setCustomersite]);

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { data: lineups } = useLineups(onError);

  const lineupSelect = useMemo(
    () => lineups?.map((lineup) => ({ label: lineup.name, value: lineup.id as unknown as string })) || [],
    [lineups]
  );

  const { mutateAsync: createCustomersite } = useCreateCustomersite(onError);
  const { mutateAsync: updateCustomersite } = useUpdateCustomersite(onError);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomersite({
      ...customersite,
      active: e.target.checked,
    });
  };

  const onChange = (type: QuestionnaireType) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setCustomersite({
        ...customersite,
        [type]: e.target.value,
      });
  };

  const onLineupChange = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue: OptionType) =>
      setCustomersite((prevCustomersite) => ({
        ...prevCustomersite,
        lineup_id: Number(newValue.value),
      })),
    [setCustomersite]
  );

  const areValidInputs = useCallback(
    (customersite: CreateCustomersiteDTO) =>
      (customersite.identifier as string).length > 0 && (customersite.name as string).length > 0 && customersite.lineup_id,
    []
  );

  const handleCancel = useCallback(() => navigate("/app/customersites"), [navigate]);

  const handleSave = useCallback(async () => {
    if (areValidInputs(customersite)) {
      setIsSaveClicked(true);
      if (identifier) {
        delete customersite.origins;
        delete customersite.identifier;
        await updateCustomersite({ identifier, customersite });
      } else {
        await createCustomersite(customersite);
      }
      handleCancel();
    } else {
      setFormError({
        identifier: (customersite.identifier as string).length === 0,
        name: (customersite.name as string).length === 0,
        lineup: !customersite.lineup_id,
      });
    }
  }, [customersite, setFormError, createCustomersite, updateCustomersite, areValidInputs, identifier, handleCancel, setIsSaveClicked]);

  if (error instanceof Error) return <PageError message={error.message} />;

  if (isLoading || (isSaveClicked && !errorMessage)) return <PageLoading />;

  return (
    <>
      <CustomTextField
        label="Customer Site Name"
        error={formError.name}
        value={customersite.name as string}
        onChange={onChange("name")}
        required
      />
      <EmptyRow />

      <Grid container justifyContent={"center"}>
        <Grid item xs={11}>
          <CustomTextField
            label="Customer Site Identifier"
            error={formError.identifier}
            value={customersite.identifier as string}
            onChange={onChange("identifier")}
            required
            disabled={!!identifier}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Changing the customer site identifier can break the streaming for a site entirely" arrow placement="top-start">
            <InfoIcon color="secondary" fontSize="large" />
          </Tooltip>
        </Grid>
      </Grid>
      <EmptyRow />

      <CustomAutocomplete
        value={(customersite.lineup_id as unknown as string) || ""}
        onChange={onLineupChange}
        error={formError.lineup}
        options={lineupSelect}
        label="Default Lineup"
      />
      <EmptyRow />

      <FormControl variant="outlined" fullWidth>
        <FormControlLabel label="Active" control={<Switch checked={customersite.active} onChange={onSwitchChange} />} />
      </FormControl>
      <EmptyRow />

      <Actions handleCancel={handleCancel} handleSave={handleSave} />

      <CommandError message={errorMessage} />
    </>
  );
}
