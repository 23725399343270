import Api from "../../dependency/Api";
import ILineupRepository, { LineupDTO } from "./ILineupRepository";

export default class LineupRepository implements ILineupRepository {
  constructor(private readonly _api: Api) {}

  async lineups(): Promise<LineupDTO[]> {
    try {
      const lineups = await this._api.request<LineupDTO[]>("/lineups", "GET", undefined, true);
      return lineups;
    } catch (error) {
      throw error;
    }
  }

  async lineup(id: number): Promise<LineupDTO> {
    try {
      const lineup = await this._api.request<LineupDTO>(`/lineups/${id}`, "GET", undefined, true);
      return lineup;
    } catch (error) {
      throw error;
    }
  }

  async createLineup(name: string): Promise<LineupDTO> {
    try {
      const lineup = await this._api.request<LineupDTO>("/lineups", "POST", { name }, true);
      return lineup;
    } catch (error) {
      throw error;
    }
  }

  async updateLineup(id: number, name: string): Promise<LineupDTO> {
    try {
      const lineup = await this._api.request<LineupDTO>(`/lineups/${id}`, "PATCH", { name }, true);
      return lineup;
    } catch (error) {
      throw error;
    }
  }

  async deleteLineup(id: number): Promise<void> {
    try {
      await this._api.request<LineupDTO>(`/lineups/${id}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }

  async addPackageToLineup(id: number, package_id: number): Promise<LineupDTO> {
    try {
      const lineup = await this._api.request<LineupDTO>(`/lineups/${id}/packages`, "POST", { package_id }, true);
      return lineup;
    } catch (error) {
      throw error;
    }
  }

  async deletePackageFromLineup(id: number, package_id: number): Promise<LineupDTO> {
    try {
      const lineup = await this._api.request<LineupDTO>(`/lineups/${id}/packages/${package_id}`, "DELETE", undefined, true);
      return lineup;
    } catch (error) {
      throw error;
    }
  }

  async updatePackagePosition(id: number, package_id: number, position: number): Promise<LineupDTO> {
    try {
      const lineup = await this._api.request<LineupDTO>(`/lineups/${id}/packages/${package_id}`, "PATCH", { position }, true);
      return lineup;
    } catch (error) {
      throw error;
    }
  }
}
