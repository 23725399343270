import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { useStreams } from "../../common/hook/useStreamData";
import { useCreateLogo, useLogo, useLogos, useUpdateLogo } from "../../common/hook/useLogoData";
import LogoUploadView from "./LogoUploadView";
import { useQueryClient } from "react-query";
import PageLoading from "../../common/components/PageLoading";

const initialFormError = {
  name: false,
  file: false,
};

export default function LogoInfoView() {
  const [name, setName] = useState("");
  const [file, setFile] = useState<File>();
  const [formError, setFormError] = useState(initialFormError);
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { name: logoName } = useParams();

  useEffect(() => {
    if (logoName) setName(logoName);
  }, [logoName, setName]);

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );
  const onSuccess = useCallback(() => queryClient.invalidateQueries("logos"), [queryClient]);

  const { data: streams } = useStreams(onError);
  const { data: logos } = useLogos(onError);

  const logoNames = useMemo(() => logos?.map((logo) => logo.name) || [], [logos]);
  const streamOptions = useMemo(
    () => streams?.filter((stream) => !logoNames.includes(stream.code)).map((stream) => stream.code) || [],
    [streams, logoNames]
  );

  const { data } = useLogo(logoName as string);

  const { mutateAsync: createLogo } = useCreateLogo(onError);
  const { mutateAsync: updateLogo } = useUpdateLogo(onError, onSuccess);

  const onNameChange = useCallback((e: SyntheticEvent<Element, Event>, newValue: string) => setName(newValue), [setName]);

  const uploadFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files) {
        setFile(files[0]);
      }
    },
    [setFile]
  );

  const handleCancel = useCallback(() => navigate("/app/logos"), [navigate]);

  const handleSave = useCallback(async () => {
    if (name && file) {
      setIsSaveClicked(true);
      if (logoName) {
        await updateLogo({ name, file });
      } else {
        await createLogo({ name, file });
      }
      handleCancel();
    } else {
      setFormError({
        name: !name,
        file: !file,
      });
    }
  }, [name, file, logoName, setFormError, createLogo, updateLogo, handleCancel, setIsSaveClicked]);

  if (isSaveClicked && !errorMessage) return <PageLoading />;

  return (
    <>
      <Autocomplete
        freeSolo
        disableClearable
        options={streamOptions}
        onInputChange={onNameChange}
        disabled={!!logoName}
        value={name}
        renderInput={(params) => <TextField {...params} label="Stream Code" required error={formError.name} size="small" />}
      />
      <EmptyRow />

      <LogoUploadView uploadFile={uploadFile} error={formError.file} />

      <EmptyRow />

      {logoName && data && (
        <img src={URL.createObjectURL(data)} alt={`Logo ${logoName} couldn't be loaded`} loading="lazy" width="400px" height="200px" />
      )}
      <EmptyRow />

      <Actions handleCancel={handleCancel} handleSave={handleSave} />

      <CommandError message={errorMessage} />
    </>
  );
}
