import { DialogContent, DialogTitle, SelectChangeEvent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import CustomSelect from "../../common/components/basics/CustomSelect";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { UpTransition } from "../../common/components/UpTransition";
import { useUpdateCustomersite, useUpdateTrigger } from "../../common/hook/useCustomersiteData";
import { DialogData } from "../../common/hook/useDialog";
import BulkActionDeactivationView from "./BulkActionDeactivationView";

type Props = {
  data: DialogData;
  close(): void;
};

const BULK_ACTIONS = ["Update Lineup", "Activate", "Deactivate"];
const bulkActionsSelect = BULK_ACTIONS.map((action) => ({ value: action, label: action }));

export const BULK_ACTIONS_DIALOG_ID = "bulk-actions";

export default function BulkActionsDialog({ data, close }: Props) {
  const { identifiers } = data.data;

  const [action, setAction] = useState(BULK_ACTIONS[0]);
  const [isDeactivation, setIsDeactivation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const onSuccess = useCallback(() => queryClient.invalidateQueries("customersites"), [queryClient]);

  const { mutateAsync: updateTrigger } = useUpdateTrigger(onError);
  const { mutateAsync: updateCustomersite } = useUpdateCustomersite(onError, onSuccess);

  const setInitialState = useCallback(() => {
    setAction(BULK_ACTIONS[0]);
    setIsDeactivation(false);
    setErrorMessage("");
  }, [setAction, setIsDeactivation, setErrorMessage]);

  const handleCancel = useCallback(() => {
    setInitialState();
    close();
  }, [close, setInitialState]);

  const handleActionChange = useCallback((e: SelectChangeEvent<string | number>) => setAction(e.target.value as string), [setAction]);

  const updateTriggerBulk = useCallback(async () => {
    const promises = identifiers.map((identifier: string) => updateTrigger(identifier));
    await Promise.all(promises);
  }, [updateTrigger, identifiers]);

  const updateCustomersites = useCallback(
    async (active: boolean) => {
      const promises = identifiers.map((identifier: string) => updateCustomersite({ identifier, customersite: { active } }));
      await Promise.all(promises);
    },
    [updateCustomersite, identifiers]
  );

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      switch (action) {
        case BULK_ACTIONS[0]:
          await updateTriggerBulk();
          handleCancel();
          break;
        case BULK_ACTIONS[1]:
          await updateCustomersites(true);
          handleCancel();
          break;
        case BULK_ACTIONS[2]:
          setIsDeactivation(true);
      }
    },
    [handleCancel, action, setIsDeactivation, updateTriggerBulk, updateCustomersites]
  );

  if (data.id !== BULK_ACTIONS_DIALOG_ID) return null;

  if (isDeactivation)
    return <BulkActionDeactivationView updateCustomersites={updateCustomersites} close={handleCancel} errorMessage={errorMessage} />;

  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="bulk-actions-dialog" fullWidth maxWidth="xs">
      <form onSubmit={submitAction}>
        <DialogTitle>Select Bulk Action:</DialogTitle>
        <DialogContent>
          <EmptyRow />
          <CustomSelect label="" value={action} values={bulkActionsSelect} onChange={handleActionChange} />
          <EmptyRow />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small" color="error">
            Confirm
          </Button>
        </DialogActions>
      </form>
      <CommandError message={errorMessage} />
    </Dialog>
  );
}
