import jwtDecode, { JwtPayload } from "jwt-decode";

export class UndefinedTokenError extends Error {}

type JwtToken = JwtPayload & { realm_access: { roles: string[] } };

export default class TokenManager {
  public static readonly KEY = "APP_TOKEN";

  hasToken(): boolean {
    return window.localStorage.getItem(TokenManager.KEY) !== null;
  }

  token(): any {
    const token = window.localStorage.getItem(TokenManager.KEY);
    if (token === null) throw new UndefinedTokenError();
    return JSON.parse(token);
  }

  storeToken(newToken: any): void {
    window.localStorage.setItem(TokenManager.KEY, JSON.stringify(newToken));
    this.emitChange();
  }

  deleteToken(): void {
    window.localStorage.removeItem(TokenManager.KEY);
    this.emitChange();
  }

  isSuperUser(): boolean {
    const token = jwtDecode<JwtToken>(this.token().access_token);
    return token.realm_access.roles.includes("cdn-admin");
  }

  private emitChange() {
    window.dispatchEvent(new Event("token-status-changed"));
  }
}
