import { IconButton, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { DELETE_LOGO_DIALOG_ID } from "./DeleteLogoDialog";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Tooltip title="Edit Logo" placement="top-start" arrow>
        <Link component={RouterLink} to={`/app/logos/${params.value}`}>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "path",
    headerName: "Logo",
    flex: 1,
    align: "center",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    renderCell: (params) => <img src={params.value} alt={`${params.row.name}`} width="100px" height="100%" loading="lazy" />,
  },
];

interface Props {
  openDialog(id: string, data: any): void;
}

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <Tooltip title="Delete" placement="top-start" arrow>
      <>
        <IconButton
          size="small"
          color="primary"
          disabled={params.row.name === "default"}
          onClick={() => openDialog(DELETE_LOGO_DIALOG_ID, { name: params.row.name })}
        >
          <DeleteIcon />
        </IconButton>
      </>
    </Tooltip>
  ),
});

export default columns;
