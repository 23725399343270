import { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomErrorOverlay } from "../../common/components/table/CustomErrorOverlay";
import { CustomLoadingOverlay } from "../../common/components/table/CustomLoadingOverlay";
import { CustomNoResultOverlay } from "../../common/components/table/CustomNoResultOverlay";
import { CustomNoRowsOverlay } from "../../common/components/table/CustomNoRowsOverlay";
import columns, { getActionsColumn } from "./Columns";
import { CustomToolbar } from "./CustomToolbar";
import { StreamDTO } from "../../common/repository/IStreamRepository";
import { OriginDTO } from "../../common/repository/IOriginRepository";

interface Props {
  streams: StreamDTO[];
  origins: OriginDTO[];
  openDialog(id: string, data: string): void;
}

export default function StreamListView({ streams, origins, openDialog }: Props) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);

  const streamRows = useMemo(
    () =>
      streams.map((stream) => ({
        ...stream,
        origin_id: origins?.find((origin) => origin.id === stream.origin_id)?.name,
      })),
    [streams, origins]
  );

  const onPageChange = (page: number) => setPage(page);
  const actionsColumn = getActionsColumn({ openDialog });

  return (
    <DataGrid
      autoHeight
      rows={streamRows}
      columns={[...columns, actionsColumn]}
      pageSize={pageSize}
      page={page}
      density="standard"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableSelectionOnClick
      disableColumnMenu
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
        NoRowsOverlay: CustomNoRowsOverlay,
        ErrorOverlay: CustomErrorOverlay,
        Toolbar: CustomToolbar,
        NoResultsOverlay: CustomNoResultOverlay,
      }}
    />
  );
}
