import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { useUpdateOrigin } from "../../common/hook/useOriginData";

type Props = {
  data: DialogData;
  close(): void;
};

export const ACTIVE_ORIGIN_DIALOG_ID = "active-origin";

export default function ActiveOriginDialog({ data, close }: Props) {
  const [activeOriginName, setActiveOriginName] = useState("");
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { id, name, active } = data.data;

  const queryClient = useQueryClient();

  const onError = useCallback((error: Error) => setErrorMessage(error.message), [setErrorMessage]);

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries("origins");
  }, [queryClient]);

  const { mutateAsync: updateOrigin } = useUpdateOrigin(onError, onSuccess);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;

    setActiveOriginName(value);
  };

  const handleClose = () => {
    setFormError(false);
    close();
  };

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (activeOriginName === name) {
        await updateOrigin({ id, origin: { active: !active } });
        setFormError(false);
        close();
      } else {
        setFormError(true);
      }
    },
    [close, id, name, active, activeOriginName, updateOrigin]
  );

  const dialogText =
    active === true
      ? "This will make all streams served from this origin unavailable until reactivation."
      : "This will make all streams served from this origin available.";

  const dialogLabel = active === true ? "Enter origin name to confirm deactivation" : "Enter origin name to confirm activation";

  const confirmBtn = active === true ? "Deactivate" : "Activate";

  if (data.id !== ACTIVE_ORIGIN_DIALOG_ID) return null;

  return (
    <>
      <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="active-origin-dialog" fullWidth maxWidth="xs">
        <form onSubmit={submitAction}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            {dialogText}
            <EmptyRow />
            <CustomTextField label={dialogLabel} value={activeOriginName} onChange={onChange} error={formError} hasHelperText />
            <EmptyRow />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" size="small">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="error" size="small">
              {confirmBtn}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CommandError message={errorMessage} />
    </>
  );
}
