import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";

type LineupPackage = {
  id: number;
  package_id: number;
};
type PackagePosition = {
  id: number;
  package_id: number;
  position: number;
};

const fetchLineups = () => dependency.repositories.lineupRepository.lineups();
const fetchLineup = (id: number) => dependency.repositories.lineupRepository.lineup(id);
const createLineup = async (name: string) => await dependency.repositories.lineupRepository.createLineup(name);
const updateLineup = async ({ id, name }: { id: number; name: string }) =>
  await dependency.repositories.lineupRepository.updateLineup(id, name);
const deleteLineup = async (id: number) => await dependency.repositories.lineupRepository.deleteLineup(id);
const addPackageToLineup = async ({ id, package_id }: LineupPackage) =>
  await dependency.repositories.lineupRepository.addPackageToLineup(id, package_id);
const deletePackageFromLineup = async ({ id, package_id }: LineupPackage) =>
  await dependency.repositories.lineupRepository.deletePackageFromLineup(id, package_id);
const updatePackagePosition = async ({ id, package_id, position }: PackagePosition) =>
  await dependency.repositories.lineupRepository.updatePackagePosition(id, package_id, position);

export const useLineups = (onError: (error: Error) => void) => useQuery("lineups", fetchLineups, { onError });

export const useLineup = (id: number) =>
  useQuery(["lineup", id], () => fetchLineup(id), {
    enabled: !!id,
  });

export const useCreateLineup = (onError: (error: Error) => void) => useMutation(createLineup, { onError });

export const useUpdateLineup = (onError: (error: Error) => void) => useMutation(updateLineup, { onError });

export const useDeleteLineup = (onSuccess: () => void, onError: (error: Error) => void) =>
  useMutation(deleteLineup, { onSuccess, onError });

export const useAddPackageToLineup = (onError: (error: Error) => void, onSuccess: () => void) =>
  useMutation(addPackageToLineup, { onError, onSuccess });

export const useDeletePackageFromLineup = (onError: (error: Error) => void) => useMutation(deletePackageFromLineup, { onError });

export const useUpdatePackagePosition = (onError: (error: Error) => void) => useMutation(updatePackagePosition, { onError });
