import { Container, Paper } from "@mui/material";
import ChannelInfoView from "./ChannelInfoView";

export default function Channel() {
  return (
    <Container maxWidth="lg">
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <ChannelInfoView />
      </Paper>
    </Container>
  );
}
