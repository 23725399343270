import { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomErrorOverlay } from "../../common/components/table/CustomErrorOverlay";
import { CustomLoadingOverlay } from "../../common/components/table/CustomLoadingOverlay";
import { CustomNoResultOverlay } from "../../common/components/table/CustomNoResultOverlay";
import { CustomNoRowsOverlay } from "../../common/components/table/CustomNoRowsOverlay";
import columns, { getActionsColumn } from "./Columns";
import { CustomToolbar } from "./CustomToolbar";
import { CustomersiteDTO } from "../../common/repository/ICustomersiteRepository";
import { LineupDTO } from "../../common/repository/ILineupRepository";

interface Props {
  customersites: CustomersiteDTO[];
  lineups: LineupDTO[];
  openDialog(id: string, data: string): void;
}

export default function CustomersiteListView({ customersites, lineups, openDialog }: Props) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);

  const customersiteRows = useMemo(
    () =>
      customersites.map((customersite) => ({
        ...customersite,
        lineup_id: lineups?.find((lineup) => lineup.id === customersite.lineup_id)?.name,
      })),
    [customersites, lineups]
  );

  const onPageChange = (page: number) => setPage(page);
  const actionsColumn = getActionsColumn({ openDialog });

  return (
    <DataGrid
      autoHeight
      rows={customersiteRows}
      columns={[...columns, actionsColumn]}
      getRowId={(row) => row.identifier}
      pageSize={pageSize}
      page={page}
      density="standard"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableSelectionOnClick
      disableColumnMenu
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
        NoRowsOverlay: CustomNoRowsOverlay,
        ErrorOverlay: CustomErrorOverlay,
        Toolbar: CustomToolbar,
        NoResultsOverlay: CustomNoResultOverlay,
      }}
    />
  );
}
