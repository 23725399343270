import React from "react";
import { List, ListItem, ListItemProps, ListItemIcon, ListItemText, Box } from "@mui/material";
import { Link, LinkProps, useLocation } from "react-router-dom";
import ChannelIcon from "@mui/icons-material/Tv";
import OriginIcon from "@mui/icons-material/Storage";
import PackageIcon from "@mui/icons-material/Widgets";
import LogoIcon from "@mui/icons-material/Image";
import StreamIcon from "@mui/icons-material/OndemandVideo";
import CustomersiteIcon from "@mui/icons-material/Business";
import LineupIcon from "@mui/icons-material/Subject";
import BalancingIcon from "@mui/icons-material/Balance";
import { styled } from "@mui/material/styles";
import dependency from "../dependency";
import { EmptyRow } from "../common/components/EmptyRow";

export interface ItemProps extends ListItemProps {
  component: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
  to: LinkProps["to"];
}

const Item = styled(ListItem)<ItemProps>(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export default function MenuItems({ open }: { open: boolean }) {
  const location = useLocation();

  const isSuperUser = React.useMemo(() => dependency.tokenManager.isSuperUser(), []);

  const isSelected = React.useCallback((url: string) => location.pathname.startsWith(url), [location]);

  const items = [
    {
      name: "CDN",
      show: isSuperUser,
      subitems: [
        { name: "Origins", url: "/app/origins", icon: <OriginIcon /> },
        { name: "Streams", url: "/app/streams", icon: <StreamIcon /> },
        { name: "Balancing", url: "/app/balancing", icon: <BalancingIcon /> },
      ],
    },
    {
      name: "Metadata",
      show: true,
      subitems: [
        { name: "Channels", url: "/app/channels", icon: <ChannelIcon /> },
        { name: "Logos", url: "/app/logos", icon: <LogoIcon /> },
      ],
    },
    {
      name: "Allocation",
      show: true,
      subitems: [
        { name: "Packages", url: "/app/packages", icon: <PackageIcon /> },
        { name: "Lineups", url: "/app/lineups", icon: <LineupIcon /> },
        { name: "Customer Sites", url: "/app/customersites", icon: <CustomersiteIcon /> },
      ],
    },
  ];

  return (
    <List>
      {items.map((item, index) => (
        <Box key={index} hidden={!item.show}>
          <ListItem>
            <ListItemText primary={item.name} hidden={!open} />
          </ListItem>

          {item.subitems.map((subitem, index) => (
            <Item key={index} selected={isSelected(subitem.url)} component={Link} to={subitem.url}>
              <ListItemIcon>{subitem.icon}</ListItemIcon>
              <ListItemText primary={subitem.name} />
            </Item>
          ))}
          <EmptyRow />
        </Box>
      ))}
    </List>
  );
}
