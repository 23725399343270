import { Box, IconButton, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { DELETE_LINEUP_DIALOG_ID } from "./DeleteLineupDialog";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Tooltip title="Edit Lineup" placement="top-start" arrow>
        <Link component={RouterLink} to={`/app/lineups/${params.row.id}`}>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "packages",
    headerName: "Packages",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => <Box>{params.row.packages.length}</Box>,
  },
];

interface Props {
  openDialog(id: string, data: { id: number }): void;
}

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <Tooltip title="Delete" placement="top-start" arrow>
      <IconButton size="small" color="primary" onClick={() => openDialog(DELETE_LINEUP_DIALOG_ID, { id: params.row.id })}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  ),
});

export default columns;
