import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import CommandError from "../../common/components/CommandError";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { useUpdateTrigger } from "../../common/hook/useCustomersiteData";

type Props = {
  data: DialogData;
  close(): void;
};

export const UPDATE_TRIGGER_DIALOG_ID = "update-trigger";

export default function UpdateTriggerDialog({ data, close }: Props) {
  const { identifier } = data.data;

  const [errorMessage, setErrorMessage] = useState("");

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { mutateAsync: updateTrigger } = useUpdateTrigger(onError);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await updateTrigger(identifier);
      close();
    },
    [identifier, close, updateTrigger]
  );

  if (data.id !== UPDATE_TRIGGER_DIALOG_ID) return null;

  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="update-trigger-dialog" fullWidth maxWidth="xs">
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={close} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="error" size="small">
            Update
          </Button>
        </DialogActions>
        <CommandError message={errorMessage} />
      </form>
    </Dialog>
  );
}
