import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import BulkIcon from "@mui/icons-material/LibraryAddCheck";
import { BULK_ACTIONS_DIALOG_ID } from "./BulkActionsDialog";

interface Props {
  identifiers: string[];
  openDialog(id: string, data: { identifiers: string[] }): void;
}

export function CustomToolbar({ openDialog, identifiers }: Props) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        size="small"
        startIcon={<BulkIcon />}
        disabled={identifiers.length === 0}
        onClick={() => openDialog(BULK_ACTIONS_DIALOG_ID, { identifiers: identifiers })}
      >
        Bulk Actions
      </Button>
    </GridToolbarContainer>
  );
}
