import DeleteStreamDialog from "./DeleteStreamDialog";
import StreamListView from "./StreamListView";
import useDialog from "../../common/hook/useDialog";
import PageError from "../../common/components/PageError";
import { useStreams } from "../../common/hook/useStreamData";
import { useState } from "react";
import PageLoading from "../../common/components/PageLoading";
import { useOrigins } from "../../common/hook/useOriginData";

export default function StreamList() {
  const [error, setError] = useState("");

  const { data: streams, isLoading } = useStreams((error: Error) => setError(error.message));
  const { data: origins } = useOrigins((error: Error) => setError(error.message));

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (error) return <PageError message={error} />;

  if (isLoading) return <PageLoading />;

  return (
    <>
      <StreamListView streams={streams || []} origins={origins || []} openDialog={openDialog} />
      <DeleteStreamDialog data={dialogData} close={closeDialog} />
    </>
  );
}
