import Api from "../../dependency/Api";
import ILogoRepository, { CreateLogoDTO, LogoDTO } from "./ILogoRepository";

export default class LogoRepository implements ILogoRepository {
  constructor(private readonly _api: Api) {}

  async logos(): Promise<LogoDTO[]> {
    try {
      const logos = [];
      const logoNames = await this._api.request<{ name: string }[]>("/logos", "GET", undefined, true);
      for (const logoName of logoNames) {
        const file = await this.logo(logoName.name);
        logos.push({
          name: logoName.name,
          path: URL.createObjectURL(file),
        });
      }
      return logos;
    } catch (error) {
      throw error;
    }
  }

  async logo(name: string): Promise<Blob> {
    try {
      const logo = await this._api.request<Blob>(`/logos/${name}`, "GET", undefined, false, undefined, "blob");
      return logo;
    } catch (error) {
      throw error;
    }
  }

  async createLogo(logo: CreateLogoDTO): Promise<void> {
    try {
      const { name, file } = logo;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("logo_file", file);
      await this._api.request("/logos", "POST", formData, true, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      throw error;
    }
  }

  async updateLogo(name: string, file: File): Promise<void> {
    try {
      const formData = new FormData();
      formData.append("logo_file", file);
      await this._api.request(`/logos/${name}`, "PATCH", formData, true, {
        "Content-Type": "multipart/form-data",
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteLogo(name: string): Promise<void> {
    try {
      await this._api.request(`/logos/${name}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }
}
