import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";

type PackageChannel = {
  id: number;
  base_code: string;
};
type ChannelPosition = {
  id: number;
  base_code: string;
  position: number;
};

const fetchPackages = () => dependency.repositories.packageRepository.packages();
const fetchPackage = (id: number) => dependency.repositories.packageRepository.package(id);
const createPackage = async (name: string) => await dependency.repositories.packageRepository.createPackage(name);
const updatePackage = async ({ id, name }: { id: number; name: string }) =>
  await dependency.repositories.packageRepository.updatePackage(id, name);
const deletePackage = async (id: number) => await dependency.repositories.packageRepository.deletePackage(id);
const addChannelToPackage = async ({ id, base_code }: PackageChannel) =>
  await dependency.repositories.packageRepository.addChannelToPackage(id, base_code);
const deleteChannelFromPackage = async ({ id, base_code }: PackageChannel) =>
  await dependency.repositories.packageRepository.deleteChannelFromPackage(id, base_code);
const updateChannelPosition = async ({ id, base_code, position }: ChannelPosition) =>
  await dependency.repositories.packageRepository.updateChannelPosition(id, base_code, position);

export const usePackages = (onError: (error: Error) => void) => useQuery("packages", fetchPackages, { onError });

export const usePackage = (id: number) =>
  useQuery(["package", id], () => fetchPackage(id), {
    enabled: !!id,
  });

export const useCreatePackage = (onError: (error: Error) => void) => useMutation(createPackage, { onError });

export const useUpdatePackage = (onError: (error: Error) => void) => useMutation(updatePackage, { onError });

export const useDeletePackage = (onSuccess: () => void, onError: (error: Error) => void) =>
  useMutation(deletePackage, { onSuccess, onError });

export const useAddChannelToPackage = (onError: (error: Error) => void, onSuccess: () => void) =>
  useMutation(addChannelToPackage, { onError, onSuccess });

export const useDeleteChannelFromPackage = (onError: (error: Error) => void) => useMutation(deleteChannelFromPackage, { onError });

export const useUpdateChannelPosition = (onError: (error: Error) => void) => useMutation(updateChannelPosition, { onError });
