import React from "react";
import { GridOverlay } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

const style: React.CSSProperties = {
  position: "absolute",
  top: 50,
  width: "100%",
  textAlign: "center",
};

export function CustomNoResultOverlay() {
  return (
    <GridOverlay>
      <div style={style}>
        <Typography variant="body1">No result</Typography>
      </div>
    </GridOverlay>
  );
}
