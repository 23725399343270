import { Button, Chip, IconButton, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { TagDTO } from "../../common/repository/ITagRepository";
import { STREAMS_CHANNEL_DIALOG_ID } from "./StreamsChannelDialog";
import { DELETE_CHANNEL_DIALOG_ID } from "./DeleteChannelDialog";
import { Delete as DeleteIcon } from "@mui/icons-material";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Channel Name",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "language_code",
    headerName: "Language",
    flex: 0.4,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "tags",
    headerName: "Tags",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <>
        {params.row.tags.map((tag: TagDTO) => (
          <Chip key={tag.id} variant="filled" color="secondary" sx={{ m: 0.2 }} label={tag.name} size="small" />
        ))}
      </>
    ),
  },
  {
    field: "epg_channel_id",
    headerName: "EPG ID",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
  },
];

interface Props {
  openDialog(id: string, data: any): void;
}

export const getStreamsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "streams",
  headerName: "Streams",
  flex: 0.3,
  align: "center",
  headerAlign: "center",
  renderCell: (params) =>
    params.value.length === 0 ? (
      <Tooltip title="No Streams" placement="top-start" arrow>
        <Button>{params.value.length}</Button>
      </Tooltip>
    ) : (
      <Tooltip title="Show Streams" placement="top-start" arrow>
        <Button onClick={() => openDialog(STREAMS_CHANNEL_DIALOG_ID, { streams: params.value })}>{params.value.length}</Button>
      </Tooltip>
    ),
});

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 0.3,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <Tooltip title="Delete" placement="top-start" arrow>
      <IconButton size="small" color="primary" onClick={() => openDialog(DELETE_CHANNEL_DIALOG_ID, { base_code: params.row.base_code })}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  ),
});

export const getBaseCodeColumn = ({ isSuperUser }: { isSuperUser: boolean }): GridColDef => ({
  field: "base_code",
  headerName: "Base Code",
  flex: 0.5,
  align: "center",
  headerAlign: "center",
  renderCell: (params) =>
    isSuperUser ? (
      <Tooltip title="Edit Channel" placement="top-start" arrow>
        <Link component={RouterLink} to={`/app/channels/${params.value}`}>
          {params.value}
        </Link>
      </Tooltip>
    ) : (
      <>{params.value}</>
    ),
});

export default columns;
