import { useQuery } from "react-query";
import dependency from "../../dependency";
import DeleteOriginDialog from "./DeleteOriginDialog";
import ActiveOriginDialog from "./ActiveOriginDialog";
import OriginListView from "./OriginListView";
import useDialog from "../../common/hook/useDialog";

export default function OriginList() {
  const fetchOrigins = async () => await dependency.repositories.originRepository.origins();

  const { data: origins, isLoading } = useQuery("origins", fetchOrigins);

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (isLoading || !origins) return null;

  return (
    <>
      <OriginListView origins={origins} openDialog={openDialog} />
      <DeleteOriginDialog data={dialogData} close={closeDialog} />
      <ActiveOriginDialog data={dialogData} close={closeDialog} />
    </>
  );
}
