import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import PageError from "../../common/components/PageError";
import PageLoading from "../../common/components/PageLoading";
import { usePackages } from "../../common/hook/usePackageData";
import { DropResult } from "react-beautiful-dnd";
import { PackageDTO } from "../../common/repository/IPackageRepository";
import {
  useAddPackageToLineup,
  useCreateLineup,
  useDeletePackageFromLineup,
  useLineup,
  useUpdateLineup,
  useUpdatePackagePosition,
} from "../../common/hook/useLineupData";
import SelectedPackagesView from "./SelectedPackagesView";
import ArrowsView from "../../common/components/ArrowsView";
import AvailablePackagesView from "./AvailablePackagesView";
import { useQueryClient } from "react-query";
import { EmptyRow } from "../../common/components/EmptyRow";

export default function LineupInfoView() {
  const [name, setName] = useState("");
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [availablePackages, setAvailablePackages] = useState<PackageDTO[]>([]);
  const [selectedPackages, setSelectedPackages] = useState<PackageDTO[]>([]);
  const [fromAvailablePackages, setFromAvailablePackages] = useState<PackageDTO[]>([]);
  const [fromSelectedPackages, setFromSelectedPackages] = useState<PackageDTO[]>([]);

  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params.id);
  const queryClient = useQueryClient();

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries("lineups");
  }, [queryClient]);

  const { data: lineup, isLoading, error } = useLineup(id);

  const { data: packages } = usePackages(onError);

  useEffect(() => {
    if (lineup) {
      setName(lineup.name);
      setAvailablePackages(packages?.filter((pack) => !lineup.packages.find((lineupPack) => lineupPack.id === pack.id)) || []);
      setSelectedPackages(lineup.packages);
    } else {
      setAvailablePackages(packages || []);
    }
  }, [lineup, setName, packages, setAvailablePackages]);

  const { mutateAsync: createLineup } = useCreateLineup(onError);
  const { mutateAsync: updateLineup } = useUpdateLineup(onError);
  const { mutateAsync: addPackageToLineup } = useAddPackageToLineup(onError, onSuccess);
  const { mutateAsync: deletePackageFromLineup } = useDeletePackageFromLineup(onError);
  const { mutateAsync: updatePackagePosition } = useUpdatePackagePosition(onError);

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(e.target.value);
    },
    [setName]
  );

  const handleCancel = useCallback(() => navigate("/app/lineups"), [navigate]);

  const handleSave = useCallback(async () => {
    if (name.length > 0) {
      setIsSaveClicked(true);
      if (id) {
        const lineup = await updateLineup({ id, name });
        const oldPackages = lineup.packages.filter((lineupPack) => !selectedPackages.find((pack) => pack.id === lineupPack.id));
        await Promise.all(oldPackages.map((pack) => deletePackageFromLineup({ id: lineup.id, package_id: pack.id })));

        let packageIndex = 1;
        for (const pack of selectedPackages) {
          if (lineup.packages.find((lineupPack, lineupPackIndex) => lineupPack.id === pack.id && packageIndex !== lineupPackIndex + 1)) {
            await updatePackagePosition({ id: lineup.id, package_id: pack.id, position: packageIndex });
          }
          if (!lineup.packages.find((lineupPack) => lineupPack.id === pack.id)) {
            await addPackageToLineup({ id: lineup.id, package_id: pack.id });
          }
          packageIndex++;
        }
      } else {
        const lineup = await createLineup(name);
        for (const pack of selectedPackages) {
          await addPackageToLineup({ id: lineup.id, package_id: pack.id });
        }
      }
      handleCancel();
    } else {
      setFormError(true);
    }
  }, [
    name,
    setFormError,
    id,
    updateLineup,
    createLineup,
    selectedPackages,
    addPackageToLineup,
    deletePackageFromLineup,
    updatePackagePosition,
    handleCancel,
    setIsSaveClicked,
  ]);

  const handleClickAvailablePackage = useCallback(
    (pack: PackageDTO) =>
      setFromAvailablePackages(
        fromAvailablePackages.includes(pack)
          ? fromAvailablePackages.filter((fromAvailablePackage) => fromAvailablePackage.id !== pack.id)
          : [...fromAvailablePackages, pack]
      ),
    [fromAvailablePackages, setFromAvailablePackages]
  );

  const handleClickSelectedPackage = useCallback(
    (pack: PackageDTO) =>
      setFromSelectedPackages(
        fromSelectedPackages.includes(pack)
          ? fromSelectedPackages.filter((fromSelectedPackage) => fromSelectedPackage.id !== pack.id)
          : [...fromSelectedPackages, pack]
      ),
    [fromSelectedPackages, setFromSelectedPackages]
  );

  const fromAvailableToSelected = useCallback(() => {
    setSelectedPackages(selectedPackages.concat(fromAvailablePackages));
    setAvailablePackages(availablePackages.filter((availablePackage) => !fromAvailablePackages.includes(availablePackage)));
    setFromAvailablePackages([]);
  }, [fromAvailablePackages, setFromAvailablePackages, selectedPackages, setSelectedPackages, availablePackages, setAvailablePackages]);

  const fromSelectedToAvailable = useCallback(() => {
    setAvailablePackages(availablePackages.concat(fromSelectedPackages));
    setSelectedPackages(selectedPackages.filter((selectedPackage) => !fromSelectedPackages.includes(selectedPackage)));
    setFromSelectedPackages([]);
  }, [fromSelectedPackages, setFromSelectedPackages, selectedPackages, setSelectedPackages, availablePackages, setAvailablePackages]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const tmp = selectedPackages[result.source.index];
      selectedPackages[result.source.index] = selectedPackages[result.destination.index];
      selectedPackages[result.destination.index] = tmp;
      setSelectedPackages(selectedPackages);
    },
    [selectedPackages, setSelectedPackages]
  );

  if (error instanceof Error) return <PageError message={error.message} />;

  if (isLoading || (isSaveClicked && !errorMessage)) return <PageLoading />;

  return (
    <>
      <Container maxWidth="sm">
        <CustomTextField label="Lineup Name" value={name} onChange={onNameChange} required error={formError} />
      </Container>

      <Grid container sx={{ mt: "5%", mb: "5%" }}>
        <Grid item xs={5}>
          <SelectedPackagesView
            selectedPackages={selectedPackages}
            fromSelectedPackages={fromSelectedPackages}
            handleClickSelectedPackage={handleClickSelectedPackage}
            handleOnDragEnd={handleOnDragEnd}
          />
        </Grid>
        <Grid item xs={2}>
          <EmptyRow />
          <EmptyRow />
          <ArrowsView fromAvailableToSelected={fromAvailableToSelected} fromSelectedToAvailable={fromSelectedToAvailable} />
        </Grid>
        <Grid item xs={5}>
          <AvailablePackagesView
            availablePackages={availablePackages}
            fromAvailablePackages={fromAvailablePackages}
            handleClickAvailablePackage={handleClickAvailablePackage}
          />
        </Grid>
      </Grid>

      <Actions handleCancel={handleCancel} handleSave={handleSave} />

      <CommandError message={errorMessage} />
    </>
  );

  // return (
  //   <>
  //     <Container maxWidth="sm">
  //       <CustomTextField label="Lineup Name" value={name} onChange={onNameChange} required error={formError} />
  //     </Container>

  //     <Grid container sx={{ mt: "5%", mb: "5%" }}>
  //       <Grid item xs={5}>
  //         <Typography variant="h5">Selected Packages</Typography>
  //         <TableContainer component={Paper}>
  //           <Table>
  //             <TableHead>
  //               <TableRow>
  //                 <TableCell>Position</TableCell>
  //                 <TableCell>Package Name</TableCell>
  //               </TableRow>
  //             </TableHead>
  //             <DragDropContext onDragEnd={handleOnDragEnd}>
  //               <Droppable droppableId="packages">
  //                 {(provided) => (
  //                   <TableBody {...provided.droppableProps} ref={provided.innerRef}>
  //                     {selectedPackages.map((pack, index) => (
  //                       <Draggable key={pack.id} draggableId={pack.id.toString()} index={index}>
  //                         {(provided) => (
  //                           <TableRow
  //                             key={pack.id}
  //                             {...provided.draggableProps}
  //                             {...provided.dragHandleProps}
  //                             ref={provided.innerRef}
  //                             onClick={() => onSelectedPackageClick(pack)}
  //                             sx={{ backgroundColor: fromSelectedPackages.includes(pack) ? "grey" : "white" }}
  //                           >
  //                             <TableCell>{index + 1}</TableCell>
  //                             <TableCell>{pack.name}</TableCell>
  //                           </TableRow>
  //                         )}
  //                       </Draggable>
  //                     ))}
  //                     {provided.placeholder}
  //                   </TableBody>
  //                 )}
  //               </Droppable>
  //             </DragDropContext>
  //           </Table>
  //         </TableContainer>
  //       </Grid>
  //       <Grid item xs={2}>
  //         <Button onClick={fromAvailableToSelected}>{"<"}</Button>
  //         <Button onClick={fromSelectedToAvailable}>{">"}</Button>
  //       </Grid>
  //       <Grid item xs={5}>
  //         <Typography variant="h5">Available Packages</Typography>
  //         <TableContainer component={Paper}>
  //           <Table>
  //             <TableHead>
  //               <TableRow>
  //                 <TableCell>Package Name</TableCell>
  //               </TableRow>
  //             </TableHead>
  //             <TableBody>
  //               {availablePackages.map((pack) => (
  //                 <TableRow
  //                   key={pack.id}
  //                   onClick={() => onAvailablePackageClick(pack)}
  //                   sx={{ backgroundColor: fromAvailablePackages.includes(pack) ? "grey" : "white" }}
  //                 >
  //                   <TableCell>{pack.name}</TableCell>
  //                 </TableRow>
  //               ))}
  //             </TableBody>
  //           </Table>
  //         </TableContainer>
  //       </Grid>
  //     </Grid>

  //     <Actions handleCancel={handleCancel} handleSave={handleSave} />

  //     <CommandError message={errorMessage} />
  //   </>
  // );
}
