import React from "react";
import { GridOverlay } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

const errorOverlayStyle: React.CSSProperties = {
  position: "absolute",
  top: 50,
  width: "100%",
  textAlign: "center",
};

export function CustomErrorOverlay() {
  return (
    <GridOverlay style={errorOverlayStyle}>
      <Typography
        variant="subtitle1"
        color="red"
        component="h1"
        textAlign="center"
      >
        An error occurred
      </Typography>
    </GridOverlay>
  );
}
