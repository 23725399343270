import React from "react";

export type DialogData = {
  id: string;
  data: any;
};

const initialDialog: DialogData = {
  id: "",
  data: {},
};

interface OpenDialog {
  (id: string, data: any): void;
}
interface CloseDialog {
  (): void;
}

export default function useDialog(): [DialogData, OpenDialog, CloseDialog] {
  const [dialogData, setDialogData] = React.useState<DialogData>(initialDialog);

  const openDialog = (id: string, data: any) =>
    setDialogData({
      id,
      data,
    });
  const closeDialog = () => setDialogData(initialDialog);

  return [dialogData, openDialog, closeDialog];
}
