import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import CommandError from "../../common/components/CommandError";
import { UpTransition } from "../../common/components/UpTransition";
import { useDeleteChannel } from "../../common/hook/useChannelData";
import { DialogData } from "../../common/hook/useDialog";

type Props = {
  data: DialogData;
  close(): void;
};

export const DELETE_CHANNEL_DIALOG_ID = "delete-channel";

export default function DeleteChannelDialog({ data, close }: Props) {
  const { base_code } = data.data;
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.setQueryData("channels", (oldQueryData: any) => {
      return oldQueryData.filter((oldData: any) => oldData.base_code !== base_code);
    });
  }, [base_code, queryClient]);
  const onError = useCallback((error: Error) => setErrorMessage(error.message), [setErrorMessage]);

  const { mutateAsync: deleteChannel } = useDeleteChannel(onSuccess, onError);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await deleteChannel(base_code);
      close();
    },
    [base_code, close, deleteChannel]
  );

  if (data.id !== DELETE_CHANNEL_DIALOG_ID) return null;

  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="delete-channel-dialog" fullWidth maxWidth="xs">
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={close} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" color="error" variant="contained" size="small">
            Delete
          </Button>
        </DialogActions>
        <CommandError message={errorMessage} />
      </form>
    </Dialog>
  );
}
