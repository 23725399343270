import { iso6392 } from "iso-639-2";
import { SyntheticEvent } from "react";
import CustomAutocomplete, { OptionType } from "../../common/components/basics/CustomAutocomplete";

interface Props {
  value: string;
  onChange: (e: SyntheticEvent<Element, Event>, newValue: OptionType) => void;
  error: boolean;
}

const languageOptions = iso6392.map((lang) => ({ label: lang.name, value: lang.iso6392B }));

export default function LanguageSelect({ value, onChange, error }: Props) {
  return <CustomAutocomplete value={value} onChange={onChange} error={error} options={languageOptions} label="Language (ISO-639-2/B)" />;
}
