import { Container, Paper } from "@mui/material";
import StreamInfoView from "./StreamInfoView";

export default function Stream() {
  return (
    <Container maxWidth="lg">
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <StreamInfoView />
      </Paper>
    </Container>
  );
}
