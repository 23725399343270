import { SelectChangeEvent } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import {
  useAddTagToChannel,
  useChannel,
  useCreateChannel,
  useDeleteTagFromChannel,
  useUpdateChannel,
} from "../../common/hook/useChannelData";
import { useCreateTag, useTags } from "../../common/hook/useTagData";
import { ChannelDTO } from "../../common/repository/IChannelRepository";
import EpgView from "./EpgView";
import LanguageSelect from "./LanguageSelect";
import TagsView from "./TagsView";
import PageLoading from "../../common/components/PageLoading";
import { OptionType } from "../../common/components/basics/CustomAutocomplete";

export type QuestionnaireType = "base_code" | "name" | "epg_channel_id";

const BASE_CODE_VALIDATION = /^[a-z0-9]+$/;
const NAME_VALIDATION = /^[a-zA-Z0-9+\-!? ]+$/;

const initialChannel: ChannelDTO = {
  base_code: "",
  name: "",
  language_code: "",
  epg_channel_id: "",
  tags: [],
  streams: [],
};
const initialFormError = {
  base_code: false,
  name: false,
  language_code: false,
};

export default function ChannelInfoView() {
  const [channel, setChannel] = useState(initialChannel);
  const [formError, setFormError] = useState(initialFormError);
  const [tags, setTags] = useState<string[]>([]);
  const [tag, setTag] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const { base_code: channel_base_code } = useParams();
  const navigate = useNavigate();

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { data } = useChannel(channel_base_code as string);
  const { data: allTags } = useTags(onError);

  const { mutateAsync: createChannel } = useCreateChannel(onError);
  const { mutateAsync: updateChannel } = useUpdateChannel(onError);
  const { mutateAsync: createTag } = useCreateTag(onError);
  const { mutateAsync: addTagToChannel } = useAddTagToChannel(onError);
  const { mutateAsync: deleteTagFromChannel } = useDeleteTagFromChannel(onError);

  const updateTags = useCallback(async () => {
    for (const tag of tags) {
      let newTag;
      if (!allTags?.find((t) => t.name === tag)) {
        newTag = await createTag(tag);
      }
      if (!channel.tags.find((t) => t.name === tag)) {
        if (!newTag) {
          newTag = allTags?.find((t) => t.name === tag);
        }
        if (newTag) {
          await addTagToChannel({ base_code: channel.base_code, tag_id: newTag.id });
        }
      }
    }
    for (const channelTag of channel.tags) {
      if (!tags.includes(channelTag.name)) {
        await deleteTagFromChannel({ base_code: channel.base_code, tag_id: channelTag.id });
      }
    }
  }, [tags, allTags, channel, addTagToChannel, createTag, deleteTagFromChannel]);

  useEffect(() => {
    setChannel(data || initialChannel);
    setTags(data ? data.tags.map((tag) => tag.name) : []);
  }, [data, setChannel, setTags]);

  const onChange = useCallback(
    (type: QuestionnaireType) => {
      return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        const value = e.target.value;
        setChannel((prevChannel) => ({
          ...prevChannel,
          [type]: value,
        }));
      };
    },
    [setChannel]
  );

  const onLanguageChange = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue: OptionType) =>
      setChannel((prevChannel) => ({
        ...prevChannel,
        language_code: newValue.value,
      })),
    [setChannel]
  );

  const handleCancel = useCallback(() => navigate("/app/channels"), [navigate]);

  const areValidInputs = useCallback(
    (base_code: string, name: string, language_code: string) =>
      base_code.length > 0 &&
      name.length > 0 &&
      language_code.length > 0 &&
      base_code.match(BASE_CODE_VALIDATION) &&
      name.match(NAME_VALIDATION),
    []
  );

  const handleSave = useCallback(async () => {
    const { base_code, name, language_code, epg_channel_id } = channel;
    if (areValidInputs(base_code, name, language_code)) {
      setIsSaveClicked(true);
      if (channel_base_code) {
        await updateChannel({ base_code: channel_base_code, channel: { name, language_code, epg_channel_id } });
      } else {
        await createChannel({ base_code, name, language_code, epg_channel_id });
      }
      await updateTags();
      handleCancel();
    } else {
      setFormError({
        base_code: base_code.length === 0 || !base_code.match(BASE_CODE_VALIDATION),
        name: name.length === 0 || !name.match(NAME_VALIDATION),
        language_code: language_code.length === 0,
      });
    }
  }, [channel, channel_base_code, createChannel, updateChannel, handleCancel, updateTags, setFormError, areValidInputs, setIsSaveClicked]);

  const handleChangeTag = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value), [setTag]);

  const handleAddTag = useCallback(() => {
    if (tag && !tags.includes(tag)) {
      setTags((prevTags) => [...prevTags, tag]);
    }
    setTag("");
  }, [setTag, setTags, tag, tags]);

  const handleDeleteTag = useCallback(
    (tagToDelete: string) => setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete)),
    [setTags]
  );

  if (isSaveClicked && !errorMessage) return <PageLoading />;

  return (
    <>
      <CustomTextField
        label="Base Code"
        value={channel.base_code}
        onChange={onChange("base_code")}
        required
        disabled={!!channel_base_code}
        error={formError.base_code}
      />
      <EmptyRow />

      <CustomTextField label="Name" required value={channel.name} onChange={onChange("name")} error={formError.name} />
      <EmptyRow />

      <LanguageSelect value={channel.language_code} onChange={onLanguageChange} error={formError.language_code} />
      <EmptyRow />

      <EpgView epg_channel_id={channel.epg_channel_id} onChange={onChange("epg_channel_id")} />
      <EmptyRow />

      <TagsView tag={tag} tags={tags} handleChangeTag={handleChangeTag} handleAddTag={handleAddTag} handleDeleteTag={handleDeleteTag} />
      <EmptyRow />

      <Actions handleCancel={handleCancel} handleSave={handleSave} />

      <CommandError message={errorMessage} />
    </>
  );
}
