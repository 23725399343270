import BalancingListView from "./BalancingListView";
import PageError from "../../common/components/PageError";
import { useState } from "react";
import PageLoading from "../../common/components/PageLoading";
import { useCustomersites } from "../../common/hook/useCustomersiteData";
import OriginsCustomersiteDialog from "./OriginsDialog";
import ActiveCustomersiteDialog from "./ActiveCustomersiteDialog";
import UpdateTriggerDialog from "./UpdateTriggerDialog";
import { useOrigins } from "../../common/hook/useOriginData";
import BulkActionsDialog from "./BulkActionsDialog";
import useDialog from "../../common/hook/useDialog";

export default function BalancingList() {
  const [error, setError] = useState("");

  const { data: customersites, isLoading } = useCustomersites((error: Error) => setError(error.message));
  const { data: origins } = useOrigins((error: Error) => setError(error.message));

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (error) return <PageError message={error} />;

  if (isLoading) return <PageLoading />;

  return (
    <>
      <BalancingListView customersites={customersites || []} openDialog={openDialog} />
      <OriginsCustomersiteDialog data={dialogData} close={closeDialog} origins={origins || []} />
      <ActiveCustomersiteDialog data={dialogData} close={closeDialog} />
      <UpdateTriggerDialog data={dialogData} close={closeDialog} />
      <BulkActionsDialog data={dialogData} close={closeDialog} />
    </>
  );
}
