import { Alert, Snackbar } from "@mui/material";

export default function CommandError({ message }: { message: string }) {
  return (
    <Snackbar open={!!message} autoHideDuration={5000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
      <Alert severity="error" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
