import { ResponseType } from "axios";
import IHttpRequester, { RequestData } from "./httpRequester/IHttpRequester";

export class ApiError extends Error {
  constructor(message: string) {
    super(`[Api] Error - ${message}`);
  }
}

export default class Api {
  constructor(private readonly _baseUrl: string, private readonly _requester: IHttpRequester) {}

  async request<T>(
    url: string,
    method: RequestData["method"],
    data: RequestData["data"],
    useToken: boolean,
    headers: any = undefined,
    responseType: ResponseType = "json"
  ): Promise<T> {
    try {
      return await this._requester.request<T>({
        url: `${this._baseUrl}/api/v2${url}`,
        method,
        data,
        headers,
        useToken,
        responseType,
      });
    } catch (error: any) {
      throw new ApiError(error.message);
    }
  }
}
