import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ChannelDTO } from "../../common/repository/IChannelRepository";
import styles from "../../common/styles";

interface Props {
  availableChannels: ChannelDTO[];
  fromAvailableChannels: ChannelDTO[];
  handleClickAvailableChannel: (channel: ChannelDTO) => void;
}

export default function AvailableChannelsView({ availableChannels, fromAvailableChannels, handleClickAvailableChannel }: Props) {
  return (
    <>
      <Typography variant="h5">Available Channels</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeader}>Channel Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableChannels.map((channel) => (
              <TableRow
                key={channel.base_code}
                onClick={() => handleClickAvailableChannel(channel)}
                sx={{ backgroundColor: fromAvailableChannels.includes(channel) ? "grey" : "white" }}
              >
                <TableCell>{channel.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
