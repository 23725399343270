import Api from "../../dependency/Api";
import IStreamRepository, { StreamDTO, CreateStreamDTO } from "./IStreamRepository";

export default class StreamRepository implements IStreamRepository {
  constructor(private readonly _api: Api) {}

  async streams(): Promise<StreamDTO[]> {
    try {
      const streams = await this._api.request<StreamDTO[]>("/streams", "GET", undefined, true);
      return streams;
    } catch (error) {
      throw error;
    }
  }

  async stream(id: number): Promise<StreamDTO> {
    try {
      const stream = await this._api.request<StreamDTO>(`/streams/${id}`, "GET", undefined, true);
      return stream;
    } catch (error) {
      throw error;
    }
  }

  async createStream(stream: CreateStreamDTO): Promise<StreamDTO> {
    try {
      const streamModel = await this._api.request<StreamDTO>("/streams", "POST", stream, true);
      return streamModel;
    } catch (error) {
      throw error;
    }
  }

  async updateStream(id: number, stream: CreateStreamDTO): Promise<StreamDTO> {
    try {
      const streamModel = await this._api.request<StreamDTO>(`/streams/${id}`, "PATCH", stream, true);
      return streamModel;
    } catch (error) {
      throw error;
    }
  }

  async deleteStream(id: number): Promise<void> {
    try {
      await this._api.request(`/streams/${id}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }
}
