import { FormControl, Select, MenuItem, InputLabel, SelectChangeEvent } from "@mui/material";

type Data = {
  label: string;
  value: string | number;
};

interface Props {
  label: string;
  values: Data[];
  value: string | number;
  error?: boolean;
  required?: boolean;
  onChange: (e: SelectChangeEvent<string | number>) => void;
}

export default function CustomSelect(props: Props) {
  return (
    <FormControl required={props.required} fullWidth error={props.error} size="small">
      <InputLabel>{props.label}</InputLabel>
      <Select onChange={props.onChange} value={props.value}>
        {props.values.map(({ label, value }, index) => (
          <MenuItem value={value} key={index}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
