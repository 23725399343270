import React, { useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomErrorOverlay } from "../../common/components/table/CustomErrorOverlay";
import { CustomLoadingOverlay } from "../../common/components/table/CustomLoadingOverlay";
import { CustomNoResultOverlay } from "../../common/components/table/CustomNoResultOverlay";
import { CustomNoRowsOverlay } from "../../common/components/table/CustomNoRowsOverlay";
import { LogoDTO } from "../../common/repository/ILogoRepository";
import { CustomToolbar } from "./CustomToolbar";
import columns, { getActionsColumn } from "./Columns";

interface Props {
  logos: LogoDTO[];
  openDialog(id: string, data: string): void;
}

export default function LogoListView({ logos, openDialog }: Props) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);

  const onPageChange = (page: number) => setPage(page);

  const actionsColumn = useMemo(() => getActionsColumn({ openDialog }), [openDialog]);

  return (
    <DataGrid
      autoHeight
      rows={logos}
      columns={[...columns, actionsColumn]}
      getRowId={(row) => row.name}
      pageSize={pageSize}
      page={page}
      density="standard"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableSelectionOnClick
      disableColumnMenu
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
        NoRowsOverlay: CustomNoRowsOverlay,
        ErrorOverlay: CustomErrorOverlay,
        Toolbar: CustomToolbar,
        NoResultsOverlay: CustomNoResultOverlay,
      }}
    />
  );
}
