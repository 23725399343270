import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Actions from "../../common/components/Actions";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import PageError from "../../common/components/PageError";
import PageLoading from "../../common/components/PageLoading";
import { useChannels } from "../../common/hook/useChannelData";
import { useOrigins } from "../../common/hook/useOriginData";
import { useCreateStream, useStream, useUpdateStream } from "../../common/hook/useStreamData";
import { CreateStreamDTO } from "../../common/repository/IStreamRepository";
import CustomAutocomplete, { OptionType } from "../../common/components/basics/CustomAutocomplete";

type QuestionnaireType = "quality" | "origin_id";

const QUALITIES = ["hd", "sd"];
const qualitySelect = QUALITIES.map((q) => ({ label: q.toUpperCase(), value: q }));

const initialStream: CreateStreamDTO = {
  code: "",
  quality: "",
  url_suffix: "",
  origin_id: 0,
};

const initialFormError = {
  channel: false,
  quality: false,
  origin: false,
  url_suffix: false,
};

export default function StreamInfoView() {
  const [stream, setStream] = useState(initialStream);
  const [channel, setChannel] = useState("");
  const [formError, setFormError] = useState(initialFormError);
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params.id);

  const { data, isLoading, error } = useStream(id);

  useEffect(() => {
    if (data) {
      setStream(data);
      setChannel(data.code.split("_")[0]);
    }
  }, [data, setStream, setChannel]);

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { data: channels } = useChannels(onError);
  const { data: origins } = useOrigins(onError);

  const channelSelect = useMemo(() => channels?.map((ch) => ({ label: ch.base_code, value: ch.base_code })) || [], [channels]);
  const originSelect = useMemo(
    () => origins?.map((origin) => ({ label: origin.name, value: origin.id as unknown as string })) || [],
    [origins]
  );

  const { mutateAsync: createStream } = useCreateStream(onError);
  const { mutateAsync: updateStream } = useUpdateStream(onError);

  useEffect(() => {
    setStream((prevStream) => ({
      ...prevStream,
      code: `${channel}_${stream.quality}`,
    }));
  }, [setStream, stream.quality, channel]);

  const onChange = (type: QuestionnaireType) => {
    return (e: SyntheticEvent<Element, Event>, newValue: OptionType) =>
      setStream({
        ...stream,
        [type]: newValue.value,
      });
  };

  const onChannelChange = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue: OptionType) => setChannel(newValue.value),
    [setChannel]
  );

  const onUrlChange = () => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setStream({
        ...stream,
        url_suffix: e.target.value,
      });
  };

  const areValidInputs = useCallback(
    (stream: CreateStreamDTO, channel: string) =>
      channel.length > 0 && stream.url_suffix.length > 0 && QUALITIES.includes(stream.quality) && stream.origin_id,
    []
  );

  const handleCancel = useCallback(() => navigate("/app/streams"), [navigate]);

  const handleSave = useCallback(async () => {
    if (areValidInputs(stream, channel)) {
      setIsSaveClicked(true);
      if (id) {
        await updateStream({ id, stream });
      } else {
        await createStream(stream);
      }
      handleCancel();
    } else {
      setFormError({
        channel: channel.length === 0,
        quality: !QUALITIES.includes(stream.quality),
        origin: !stream.origin_id,
        url_suffix: stream.url_suffix.length === 0,
      });
    }
  }, [stream, channel, setFormError, createStream, updateStream, areValidInputs, id, handleCancel, setIsSaveClicked]);

  if (error instanceof Error) return <PageError message={error.message} />;

  if ((isLoading || isSaveClicked) && !errorMessage) return <PageLoading />;

  return (
    <>
      <CustomTextField label="Stream Code" value={stream.code} disabled required />
      <EmptyRow />

      <CustomAutocomplete value={channel} onChange={onChannelChange} error={formError.channel} options={channelSelect} label="Channel" />
      <EmptyRow />

      <CustomAutocomplete
        value={stream.quality}
        onChange={onChange("quality")}
        error={formError.quality}
        options={qualitySelect}
        label="Quality"
      />
      <EmptyRow />

      <CustomAutocomplete
        value={(stream.origin_id as unknown as string) || ""}
        onChange={onChange("origin_id")}
        error={formError.origin}
        options={originSelect}
        label="Origin"
      />
      <EmptyRow />

      <CustomTextField
        label="URL suffix (/playlist.m3u8)"
        required
        value={stream.url_suffix}
        onChange={onUrlChange()}
        error={formError.url_suffix}
      />
      <EmptyRow />

      <Actions handleCancel={handleCancel} handleSave={handleSave} />

      <CommandError message={errorMessage} />
    </>
  );
}
