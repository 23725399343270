import { DialogContent, DialogContentText } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { UpTransition } from "../../common/components/UpTransition";
import { useUpdateCustomersite } from "../../common/hook/useCustomersiteData";
import { DialogData } from "../../common/hook/useDialog";

type Props = {
  data: DialogData;
  close(): void;
};

export const ACTIVE_CUSTOMERSITE_DIALOG_ID = "active-customersite";

export default function ActiveCustomersiteDialog({ data, close }: Props) {
  const [customersiteName, setCustomersiteName] = useState("");
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { identifier, name, active } = data.data;

  const onError = useCallback((error: Error) => setErrorMessage(error.message), [setErrorMessage]);

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => queryClient.invalidateQueries("customersites"), [queryClient]);

  const { mutateAsync: updateCustomersite } = useUpdateCustomersite(onError, onSuccess);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setCustomersiteName(e.target.value),
    [setCustomersiteName]
  );

  const setInitialState = useCallback(() => {
    setFormError(false);
    setErrorMessage("");
    setCustomersiteName("");
  }, [setFormError, setErrorMessage, setCustomersiteName]);

  const handleClose = useCallback(() => {
    setInitialState();
    close();
  }, [setInitialState, close]);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (customersiteName === name) {
        await updateCustomersite({ identifier, customersite: { active: !active } });
        handleClose();
      } else {
        setFormError(true);
      }
    },
    [handleClose, name, identifier, active, customersiteName, updateCustomersite]
  );

  const dialogText = useMemo(
    () => `This will ${active ? "disable" : "enable"} all TV Streaming for the customer site ${active ? "until reactivated" : ""}`,
    [active]
  );

  const dialogLabel = useMemo(() => `Enter the customer site name to confirm ${active ? "de" : ""}activation`, [active]);

  if (data.id !== ACTIVE_CUSTOMERSITE_DIALOG_ID) return null;

  return (
    <Dialog
      open={true}
      TransitionComponent={UpTransition}
      keepMounted
      aria-describedby="active-customersite-dialog"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
          <EmptyRow />
          <CustomTextField label={dialogLabel} value={customersiteName} onChange={onChange} error={formError} hasHelperText />
          <EmptyRow />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="error" size="small">
            {active ? "Deactivate" : "Activate"}
          </Button>
        </DialogActions>
      </form>
      <CommandError message={errorMessage} />
    </Dialog>
  );
}
