import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CircularProgress, Grid, Link, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import CommandError from "../../common/components/CommandError";
import dependency from "../../dependency";

function Copyright(props: any) {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" {...props}>
      <Grid item>
        <Link color="inherit" href="https://www.appmodule.net/">
          <Box
            sx={{
              width: 100,
              height: 30,
              backgroundImage: "url(/logo.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "90%",
              backgroundPosition: "",
              marginTop: (theme) => theme.spacing(1),
              marginBottom: (theme) => theme.spacing(0.5),
            }}
          />
        </Link>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "} {new Date().getFullYear()}
          {"."}
        </Typography>
      </Grid>
    </Grid>
  );
}

type User = {
  username: string;
  password: string;
};

export default function SignIn() {
  const [user, setUser] = useState<User>({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");

  const login = async ({ username, password }: User) => await dependency.authService.login(username, password);
  const loginMutation = useMutation(login, {
    onSuccess: () => {
      setError("");
    },
    onError: (error) => {
      if (error instanceof Error) setError(error.message);
    },
  });

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (user.username !== "" && user.password !== "") {
        loginMutation.mutate(user);
      }
    },
    [loginMutation, user]
  );

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUser({
        ...user,
        [event.target.name]: event.target.value.trim(),
      });
    },
    [user]
  );

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(/login.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loginMutation.isLoading ? (
            <CircularProgress size="40px" sx={{ m: 1 }} />
          ) : (
            <Avatar sx={{ m: 1, bgcolor: "secondary.dark" }}>
              <LockOutlinedIcon />
            </Avatar>
          )}
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={onSubmit} sx={{ mt: 1 }}>
            <TextField
              disabled={loginMutation.isLoading}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={onChangeInput}
            />
            <TextField
              disabled={loginMutation.isLoading}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChangeInput}
            />
            <Button type="submit" fullWidth variant="contained" disabled={loginMutation.isLoading} sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <CommandError message={error} />
            <Copyright sx={{ mt: 12 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
