import { Checkbox, DialogContent, FormControlLabel, FormGroup } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { useCallback, useEffect, useState } from "react";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { OriginDTO } from "../../common/repository/IOriginRepository";
import { useDeleteOriginFromCustomersite, useAddOriginToCustomersite } from "../../common/hook/useCustomersiteData";
import CommandError from "../../common/components/CommandError";
import { useQueryClient } from "react-query";

type Props = {
  data: DialogData;
  close(): void;
  origins: OriginDTO[];
};

export const ORIGINS_CUSTOMERSITE_DIALOG_ID = "origins-customersite";

export default function OriginsCustomersiteDialog({ data, close, origins }: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [checkedOrigins, setCheckedOrigins] = useState<number[]>([]);

  const queryClient = useQueryClient();

  const { value: csOrigins, identifier } = data.data;

  const onError = useCallback((error: Error) => setErrorMessage(error.message), [setErrorMessage]);

  const { mutateAsync: deleteOriginFromCustomersite } = useDeleteOriginFromCustomersite(onError);
  const { mutateAsync: addOriginToCustomersite } = useAddOriginToCustomersite(onError);

  useEffect(() => {
    setCheckedOrigins(csOrigins || []);
  }, [setCheckedOrigins, csOrigins]);

  const handleClose = useCallback(() => close(), [close]);

  const onChange = useCallback(
    (origin_id: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
          setCheckedOrigins((prevChecked) => [...prevChecked, origin_id]);
        } else {
          setCheckedOrigins((prevOrigins) => prevOrigins.filter((origin) => origin !== origin_id));
        }
      };
    },
    [setCheckedOrigins]
  );

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const actions = [];
      for (const origin_id of csOrigins) {
        if (!checkedOrigins.includes(origin_id)) {
          actions.push(deleteOriginFromCustomersite({ identifier, origin_id }));
        }
      }
      for (const origin_id of checkedOrigins) {
        if (!csOrigins.includes(origin_id)) {
          actions.push(addOriginToCustomersite({ identifier, origin_id }));
        }
      }
      await Promise.all(actions);
      queryClient.invalidateQueries("customersites");
      handleClose();
    },
    [handleClose, checkedOrigins, addOriginToCustomersite, deleteOriginFromCustomersite, queryClient, csOrigins, identifier]
  );

  if (data.id !== ORIGINS_CUSTOMERSITE_DIALOG_ID) return null;

  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={UpTransition}
        keepMounted
        aria-describedby="origins-customersite-dialog"
        fullWidth
        maxWidth="xs"
      >
        <form onSubmit={submitAction}>
          <DialogContent>
            <FormGroup>
              {origins.map((origin: OriginDTO) => (
                <FormControlLabel
                  key={origin.id}
                  control={
                    <Checkbox
                      checked={!!checkedOrigins.find((origin_id: number) => origin_id === origin.id)}
                      onChange={onChange(origin.id)}
                    />
                  }
                  label={origin.name}
                />
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" size="small">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="error" size="small">
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <CommandError message={errorMessage} />
    </>
  );
}
