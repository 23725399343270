import { Button, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { DELETE_ORIGIN_DIALOG_ID } from "./DeleteOriginDialog";
import { ACTIVE_ORIGIN_DIALOG_ID } from "./ActiveOriginDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    hide: true,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Tooltip title="Edit Origin" placement="top-start" arrow>
        <Link component={RouterLink} to={`/app/origins/${params.row.id}`}>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "scheme",
    headerName: "Scheme",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "url",
    headerName: "Origin URL",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "active",
    headerName: "Status",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (params.row.active === true ? "Active" : "Disabled"),
  },
];

interface Props {
  openDialog(id: string, data: any): void;
}

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 0.7,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <Button onClick={() => openDialog(ACTIVE_ORIGIN_DIALOG_ID, { id: params.row.id, name: params.row.name, active: params.row.active })}>
        {params.row.active === true ? (
          <Tooltip title="Disable" placement="top-start" arrow>
            <NotInterestedIcon />
          </Tooltip>
        ) : (
          <Tooltip title="Enable" placement="top-start" arrow>
            <TaskAltIcon />
          </Tooltip>
        )}
      </Button>
      <Button onClick={() => openDialog(DELETE_ORIGIN_DIALOG_ID, { id: params.row.id, name: params.row.name })}>
        <Tooltip title="Delete" placement="top-start" arrow>
          <DeleteIcon />
        </Tooltip>
      </Button>
    </>
  ),
});

export default columns;
