import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { useDeletePackage } from "../../common/hook/usePackageData";
import DeleteErrorView from "./DeleteErrorView";
import PackageDeletedView from "./PackageDeletedView";

interface Props {
  data: DialogData;
  close(): void;
}

export const DELETE_PACKAGE_DIALOG_ID = "delete-package";

export default function DeletePackageDialog({ data, close }: Props) {
  const { id } = data.data;
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.setQueryData("packages", (oldQueryData: any) => {
      return oldQueryData.filter((oldData: any) => oldData.id !== id);
    });
  }, [id, queryClient]);

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { mutateAsync: deletePackage } = useDeletePackage(onSuccess, onError);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await deletePackage(id);
      setIsSuccess(true);
    },
    [id, deletePackage, setIsSuccess]
  );

  const handleCancel = useCallback(() => {
    close();
    setIsSuccess(false);
    setErrorMessage("");
  }, [close, setIsSuccess, setErrorMessage]);

  if (data.id !== DELETE_PACKAGE_DIALOG_ID) return null;

  if (isSuccess) return <PackageDeletedView close={handleCancel} />;
  if (errorMessage) return <DeleteErrorView close={handleCancel} errorMessage={errorMessage} />;

  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="delete-package-dialog" fullWidth maxWidth="xs">
      <form onSubmit={submitAction}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>This can make all channels of this package unavailable.</DialogContent>
        <DialogActions>
          <Button onClick={close} variant="contained" size="small">
            Cancel
          </Button>
          <Button type="submit" color="error" variant="contained" size="small">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
