import { Grid, Paper } from "@mui/material";
import LineupList from "./LineupList";

export default function Lineups() {
  return (
    <Grid item xs={12} lg={8}>
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <LineupList />
      </Paper>
    </Grid>
  );
}
