import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClientProvider, QueryClient } from "react-query";
import reportWebVitals from "./reportWebVitals";
import { provideEnv } from "./dependency";
import { appTheme } from "./appTheme";
import { ThemeProvider } from "@emotion/react";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

(async () => provideEnv(await (await fetch("/settings.json")).json()))().then(() => {
  root.render(
    <QueryClientProvider client={queryClient}>
      {/* <React.StrictMode> */}
      <ThemeProvider theme={appTheme}>
        <App />
      </ThemeProvider>
      {/* </React.StrictMode> */}
    </QueryClientProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
