import DeleteCustomersiteDialog from "./DeleteCustomersiteDialog";
import CustomersiteListView from "./CustomersiteListView";
import PageError from "../../common/components/PageError";
import { useState } from "react";
import PageLoading from "../../common/components/PageLoading";
import { useLineups } from "../../common/hook/useLineupData";
import { useCustomersites } from "../../common/hook/useCustomersiteData";
import useDialog from "../../common/hook/useDialog";

export default function CustomersiteList() {
  const [error, setError] = useState("");

  const { data: customersites, isLoading } = useCustomersites((error: Error) => setError(error.message));
  const { data: lineups } = useLineups((error: Error) => setError(error.message));

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (error) return <PageError message={error} />;

  if (isLoading) return <PageLoading />;

  return (
    <>
      <CustomersiteListView customersites={customersites || []} lineups={lineups || []} openDialog={openDialog} />
      <DeleteCustomersiteDialog data={dialogData} close={closeDialog} />
    </>
  );
}
