import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";
import { CreateLogoDTO } from "../repository/ILogoRepository";

const fetchLogos = () => dependency.repositories.logoRepository.logos();
const fetchLogo = async (name: string) => await dependency.repositories.logoRepository.logo(name);
const createLogo = async (logo: CreateLogoDTO) => await dependency.repositories.logoRepository.createLogo(logo);
const updateLogo = async ({ name, file }: CreateLogoDTO) => await dependency.repositories.logoRepository.updateLogo(name, file);
const deleteLogo = async (name: string) => await dependency.repositories.logoRepository.deleteLogo(name);

export const useLogos = (onError: (error: Error) => void) => useQuery("logos", fetchLogos, { onError });

export const useLogo = (name: string) =>
  useQuery(["logo", name], () => fetchLogo(name), {
    enabled: !!name,
  });

export const useCreateLogo = (onError: (error: Error) => void) => useMutation(createLogo, { onError });

export const useUpdateLogo = (onError: (error: Error) => void, onSuccess: () => void) => useMutation(updateLogo, { onError, onSuccess });

export const useDeleteLogo = (onSuccess: () => void, onError: (error: Error) => void) => useMutation(deleteLogo, { onSuccess, onError });
