import React from "react";
import { GridOverlay } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";

const style: React.CSSProperties = {
  position: "absolute",
  top: 0,
  width: "100%",
};

export function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={style}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}
