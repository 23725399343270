import { Button, Grid } from "@mui/material";

interface Props {
  fromSelectedToAvailable: () => void;
  fromAvailableToSelected: () => void;
}

export default function ArrowsView({ fromSelectedToAvailable, fromAvailableToSelected }: Props) {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Button size="small" variant="contained" onClick={fromSelectedToAvailable}>
          {">"}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button size="small" variant="contained" onClick={fromAvailableToSelected}>
          {"<"}
        </Button>
      </Grid>
    </Grid>
  );
}
