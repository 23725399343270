import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import CommandError from "../../common/components/CommandError";
import { UpTransition } from "../../common/components/UpTransition";

interface Props {
  close(): void;
  errorMessage: string;
}

export default function DeleteErrorView({ close, errorMessage }: Props) {
  return (
    <Dialog open={true} TransitionComponent={UpTransition} keepMounted fullWidth maxWidth="xs">
      <DialogTitle>Error</DialogTitle>
      <DialogContent>The package is used in a lineup and cannot be deleted.</DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained" size="small">
          Cancel
        </Button>
      </DialogActions>
      <CommandError message={errorMessage} />
    </Dialog>
  );
}
