import { useMutation, useQuery } from "react-query";
import dependency from "../../dependency";
import { OriginModel } from "../repository/IOriginRepository";

const fetchOrigins = () => dependency.repositories.originRepository.origins();
const fetchOrigin = (id: number) => dependency.repositories.originRepository.origin(id);
const createOrigin = async (origin: OriginModel) => await dependency.repositories.originRepository.createOrigin(origin);
const updateOrigin = async ({ id, origin }: { id: number; origin: OriginModel }) =>
  await dependency.repositories.originRepository.updateOrigin(id, origin);
const deleteOrigin = async (id: number) => await dependency.repositories.originRepository.deleteOrigin(id);

export const useOrigins = (onError: (error: Error) => void) => useQuery("origins", fetchOrigins, { onError });

export const useOrigin = (id: number) =>
  useQuery(["origin", id], () => fetchOrigin(id), {
    enabled: !!id,
  });

export const useCreateOrigin = (onError: (error: Error) => void) => useMutation(createOrigin, { onError });

export const useUpdateOrigin = (onError: (error: Error) => void, onSuccess?: () => void) =>
  useMutation(updateOrigin, { onError, onSuccess });

export const useDeleteOrigin = (onSuccess: () => void, onError: (error: Error) => void) =>
  useMutation(deleteOrigin, { onSuccess, onError });
