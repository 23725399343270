import { TextField } from "@mui/material";

interface Props {
  label: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  hasHelperText?: boolean;
}

export default function CustomTextField({ label, value, onChange, required, error, disabled, hasHelperText }: Props) {
  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      required={required}
      value={value}
      onChange={onChange}
      error={error}
      disabled={disabled}
      size="small"
      helperText={error && hasHelperText ? "Names don't match" : ""}
    />
  );
}
