import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { PackageDTO } from "../../common/repository/IPackageRepository";
import styles from "../../common/styles";

interface Props {
  availablePackages: PackageDTO[];
  fromAvailablePackages: PackageDTO[];
  handleClickAvailablePackage: (pack: PackageDTO) => void;
}

export default function AvailablePackagesView({ availablePackages, fromAvailablePackages, handleClickAvailablePackage }: Props) {
  return (
    <>
      <Typography variant="h5">Available Packages</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeader}>Package Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availablePackages.map((pack) => (
              <TableRow
                key={pack.id}
                onClick={() => handleClickAvailablePackage(pack)}
                sx={{ backgroundColor: fromAvailablePackages.includes(pack) ? "grey" : "white" }}
              >
                <TableCell>{pack.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
