import Api from "../../dependency/Api";
import IOriginRepository, { OriginDTO, OriginModel } from "./IOriginRepository";

export default class OriginRepository implements IOriginRepository {
  constructor(private readonly _api: Api) {}

  async origins(): Promise<OriginDTO[]> {
    try {
      const origins = await this._api.request<OriginDTO[]>("/origins", "GET", undefined, true);
      return origins;
    } catch (error) {
      throw error;
    }
  }

  async origin(id: number): Promise<OriginDTO> {
    try {
      const origin = await this._api.request<OriginDTO>(`/origins/${id}`, "GET", undefined, true);
      return origin;
    } catch (error) {
      throw error;
    }
  }

  async createOrigin(origin: OriginModel): Promise<OriginDTO> {
    try {
      const originModel = await this._api.request<OriginDTO>("/origins", "POST", origin, true);
      return originModel;
    } catch (error) {
      throw error;
    }
  }

  async updateOrigin(id: number, origin: OriginModel): Promise<OriginDTO> {
    try {
      const originModel = await this._api.request<OriginDTO>(`/origins/${id}`, "PATCH", origin, true);
      return originModel;
    } catch (error) {
      throw error;
    }
  }

  async deleteOrigin(id: number): Promise<void> {
    try {
      await this._api.request(`/origins/${id}`, "DELETE", undefined, true);
    } catch (error) {
      throw error;
    }
  }
}
