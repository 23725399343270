import DeleteChannelDialog from "./DeleteChannelDialog";
import ChannelListView from "./ChannelListView";
import StreamsChannelDialog from "./StreamsChannelDialog";
import useDialog from "../../common/hook/useDialog";
import PageError from "../../common/components/PageError";
import { useCallback, useState } from "react";
import { useChannels } from "../../common/hook/useChannelData";
import PageLoading from "../../common/components/PageLoading";
import { useOrigins } from "../../common/hook/useOriginData";

export default function ChannelList() {
  const [errorMessage, setErrorMessage] = useState("");

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { data: channels, isLoading } = useChannels(onError);
  const { data: origins } = useOrigins(onError);

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (errorMessage) return <PageError message={errorMessage} />;

  if (isLoading) return <PageLoading />;

  return (
    <>
      <ChannelListView channels={channels || []} openDialog={openDialog} />
      <DeleteChannelDialog data={dialogData} close={closeDialog} />
      <StreamsChannelDialog data={dialogData} close={closeDialog} origins={origins || []} />
    </>
  );
}
