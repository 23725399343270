import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent } from "react";

export type OptionType = {
  label: string;
  value: string;
};

interface Props {
  value: string;
  onChange: (e: SyntheticEvent<Element, Event>, newValue: OptionType) => void;
  error: boolean;
  options: OptionType[];
  label: string;
}

const initialOption = {
  label: "",
  value: "",
};

export default function CustomAutocomplete({ value, onChange, error, options, label }: Props) {
  const optionss = [...options, initialOption];
  return (
    <Autocomplete
      disableClearable
      options={optionss}
      onChange={onChange}
      value={optionss.find((option) => option.value === value)}
      renderInput={(params) => <TextField {...params} label={label} required error={error} size="small" />}
    />
  );
}
