import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import CustomTextField from "../../common/components/basics/CustomTextField";
import CommandError from "../../common/components/CommandError";
import { EmptyRow } from "../../common/components/EmptyRow";
import { UpTransition } from "../../common/components/UpTransition";
import { DialogData } from "../../common/hook/useDialog";
import { useDeleteOrigin } from "../../common/hook/useOriginData";

type Props = {
  data: DialogData;
  close(): void;
};

export const DELETE_ORIGIN_DIALOG_ID = "delete-origin";

export default function DeleteOriginDialog({ data, close }: Props) {
  const [deleteOriginName, setDeleteOriginName] = useState("");
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { id, name } = data.data;

  const queryClient = useQueryClient();

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const onSuccess = useCallback(() => {
    queryClient.setQueryData("origins", (oldQueryData: any) => {
      return oldQueryData.filter((oldData: any) => oldData.id !== id);
    });
  }, [id, queryClient]);

  const { mutateAsync: deleteOrigin } = useDeleteOrigin(onSuccess, onError);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;

    setDeleteOriginName(value);
  };

  const handleClose = useCallback(() => {
    setFormError(false);
    setDeleteOriginName("");
    close();
  }, [close, setFormError, setDeleteOriginName]);

  const submitAction = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (deleteOriginName === name) {
        await deleteOrigin(id);
        handleClose();
      } else {
        setFormError(true);
      }
    },
    [handleClose, id, name, deleteOriginName, deleteOrigin]
  );

  if (data.id !== DELETE_ORIGIN_DIALOG_ID) return null;

  return (
    <>
      <Dialog open={true} TransitionComponent={UpTransition} keepMounted aria-describedby="delete-origin-dialog" fullWidth maxWidth="xs">
        <form onSubmit={submitAction}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            This will remove all streams associated with this origin and make them unavailable to the Customer Sites. The configured
            Channels will prevail.
            <EmptyRow />
            <CustomTextField
              label="Enter origin name to confirm deletion"
              onChange={onChange}
              error={formError}
              value={deleteOriginName}
              hasHelperText
            />
            <EmptyRow />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" size="small">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="error" size="small">
              Delete
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CommandError message={errorMessage} />
    </>
  );
}
