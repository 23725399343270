import useDialog from "../../common/hook/useDialog";
import PageError from "../../common/components/PageError";
import PageLoading from "../../common/components/PageLoading";
import { useCallback, useState } from "react";
import LogoListView from "./LogoListView";
import { useLogos } from "../../common/hook/useLogoData";
import DeleteLogoDialog from "./DeleteLogoDialog";

export default function LogoList() {
  const [errorMessage, setErrorMessage] = useState("");

  const onError = useCallback(
    (error: Error) => {
      setErrorMessage(error.message);
    },
    [setErrorMessage]
  );

  const { data: logos, isLoading } = useLogos(onError);

  const [dialogData, openDialog, closeDialog] = useDialog();

  if (errorMessage) return <PageError message={errorMessage} />;

  if (isLoading) return <PageLoading />;

  return (
    <>
      <LogoListView logos={logos || []} openDialog={openDialog} />
      <DeleteLogoDialog data={dialogData} close={closeDialog} />
    </>
  );
}
