import React from "react";
import { Container, Paper } from "@mui/material";
import LogoInfoView from "./LogoInfoView";

export default function Logo() {
  return (
    <Container maxWidth="lg">
      <Paper
        variant="outlined"
        sx={{
          padding: (theme) => theme.spacing(2),
        }}
      >
        <LogoInfoView />
      </Paper>
    </Container>
  );
}
