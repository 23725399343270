import { Button, Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { DELETE_STREAM_DIALOG_ID } from "./DeleteStreamDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    hide: true,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "code",
    headerName: "Stream Code",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <Tooltip title="Edit Stream" placement="top-start" arrow>
        <Link component={RouterLink} to={`/app/streams/${params.row.id}`}>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "quality",
    headerName: "Quality",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "url_suffix",
    headerName: "URL suffix",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "origin_id",
    headerName: "Origin Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

interface Props {
  openDialog(id: string, data: string): void;
}

export const getActionsColumn = ({ openDialog }: Props): GridColDef => ({
  field: "actions",
  headerName: "Actions",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <Button onClick={() => openDialog(DELETE_STREAM_DIALOG_ID, params.row.id)}>
      <DeleteIcon />
    </Button>
  ),
});

export default columns;
