import React, { useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomErrorOverlay } from "../../common/components/table/CustomErrorOverlay";
import { CustomLoadingOverlay } from "../../common/components/table/CustomLoadingOverlay";
import { CustomNoResultOverlay } from "../../common/components/table/CustomNoResultOverlay";
import { CustomNoRowsOverlay } from "../../common/components/table/CustomNoRowsOverlay";
import columns, { getActionsColumn, getBaseCodeColumn, getStreamsColumn } from "./Columns";
import { CustomToolbar } from "./CustomToolbar";
import { ChannelDTO } from "../../common/repository/IChannelRepository";
import dependency from "../../dependency";

interface Props {
  channels: ChannelDTO[];
  openDialog(id: string, data: string): void;
}

export default function ChannelListView({ channels, openDialog }: Props) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const onPageSizeChange = (pageSize: number) => setPageSize(pageSize);
  const onPageChange = (page: number) => setPage(page);

  const isSuperUser = useMemo(() => dependency.tokenManager.isSuperUser(), []);

  const baseCodeColumn = useMemo(() => getBaseCodeColumn({ isSuperUser }), [isSuperUser]);
  const streamsColumn = useMemo(() => getStreamsColumn({ openDialog }), [openDialog]);
  const actionsColumn = useMemo(() => getActionsColumn({ openDialog }), [openDialog]);
  const baseColums = useMemo(() => [baseCodeColumn, ...columns, streamsColumn], [baseCodeColumn, streamsColumn]);

  const channelColums = useMemo(
    () => (isSuperUser ? [...baseColums, actionsColumn] : baseColums),
    [baseColums, actionsColumn, isSuperUser]
  );

  return (
    <DataGrid
      autoHeight
      rows={channels}
      columns={channelColums}
      getRowId={(row) => row.base_code}
      pageSize={pageSize}
      page={page}
      density="standard"
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableSelectionOnClick
      disableColumnMenu
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
        NoRowsOverlay: CustomNoRowsOverlay,
        ErrorOverlay: CustomErrorOverlay,
        Toolbar: CustomToolbar,
        NoResultsOverlay: CustomNoResultOverlay,
      }}
      componentsProps={{
        toolbar: { isSuperUser },
      }}
    />
  );
}
