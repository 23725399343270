import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface Props {
  isSuperUser: boolean;
}

export function CustomToolbar({ isSuperUser }: Props) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button size="small" startIcon={<Add />} component={Link} to={`/app/channels/add`} sx={{ display: isSuperUser ? "flex" : "none" }}>
        Add channel
      </Button>
    </GridToolbarContainer>
  );
}
