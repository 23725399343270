import { CircularProgress, Typography } from "@mui/material";

export default function PageLoading() {
  return (
    <>
      <CircularProgress size="40px" sx={{ m: 1 }} />
      <Typography>Loading...</Typography>
    </>
  );
}
